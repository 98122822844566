import styled from "styled-components";

export const StyledSubtitles = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

export const StyledRightSide = styled.div`
  .side-controls {
    text-align: right;
    padding-top: 30px;
  }
`;

export const SideControls = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
`;