import styled from "styled-components";
import theme from "../../theme";

export const StyledMenu = styled.div`
  background: ${theme.colors.mainBackground};
  border-right: 1px solid ${theme.colors.mainBackground};
  min-height: 100%;
  top: 40px;
  padding: 20px;
  ul {
    margin: 0;
    list-style: none;
    position: sticky;
    top: 60px;
  }
  a {
    display: block;
    padding: 5px 5px;
    text-decoration: none;
    color: ${theme.colors.menuItemColor};
    transition: .3s;
    &:hover {
      color: ${theme.colors.menuItemColorHover};
    }
    &.active {
      color: ${theme.colors.menuItemColorHover};
    }
  }
  li {
    ul {
      padding-left: 10px;
    }
  }
`;