import styled from "styled-components";
import {ErrorMessage} from "formik";
import theme from "../../../theme";

export const StyledInput = styled.div`
  margin-bottom: 10px;
 input {
   border: 1px solid #66c4cd;
   outline: none;
   padding: 5px 10px;
   font-size: 18px;
   border-radius: 3px;
   width: 100%;
 }
  label {
    cursor: pointer;
    display: block;
    margin-bottom: 5px;
    color: ${theme.colors.headerBackground};
    font-weight: 700;
  }
`;

export const StyledError = styled(ErrorMessage)`
  color: #f45542;
  font-size: 12px;
  margin-top: 5px;
`;

export const FormRow = styled.div`
  margin-bottom: 20px;
`;