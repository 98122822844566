import styled from "styled-components";

export const StyledHeader = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  box-shadow: 1px -7px 10px;
  z-index: 10;
 .logo {
   color: #fff;
   font-size: 22px;
   text-decoration: none;
   font-weight: bold;
 }
  .head-link {
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    position: relative;
    top: -2px;
    letter-spacing: -0.5px;
  }
  .header-container {
    background: ${({ theme }) => theme.colors.headerBackground};
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 10;
    min-height: 40px;
  }
  
  .loginLink {
    color: #28b9c7;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
  }
  
  .menu-container {
    display: flex;
    gap: 10px;
    align-items: baseline;
    @media(max-width: 900px) {
      justify-content: space-between;
      width: 100%;
    }
  }
  
  .menu-icon {
    color: #ffffff;
    font-size: 19px;
    font-weight: bold;
  }
`;

export const StyledTrigger = styled.div`
  z-index: 10;
  background: ${({ theme }) => theme.colors.headerBackground};
  top: 0;
  right: 0;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
`;