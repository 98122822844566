import React from 'react';
import {StyledVideo} from "./styles";
import {VideoType} from "../../../../types";
import {useNavigate} from "react-router-dom";

type Props = {
    video: VideoType;
    seasonId: string;
    videoListId: string;
}
export const Video = ({video, seasonId, videoListId}: Props) => {

    const navigate = useNavigate();
    const onSeasonClick = () => {
        navigate(`/videos/${videoListId}/seasons/${seasonId}/${video.id}`);
    }

    return <StyledVideo onClick={onSeasonClick}>
        <div className="img-container">
            <img src={video.cover} alt={video.title}/>
        </div>
        <h3>{video.title}</h3>
    </StyledVideo>
}