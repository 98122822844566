import React from 'react';
import {Layout} from "../../components/layout";
import {Poster} from "./components/Poster";
import {PageWrapper, StyledSectionRow} from "./styles";
import {RandomVideos} from "./components/RandomVideos";
import {Links} from "./components/Links";
import {VideoWithSubtitle} from "./components/VideoWithSubtitle";
import {Button} from "../../components/Button";

type Props = {};
type State = {
    name: string;
    age: number;
};
export class HomeDesigned extends React.Component<Props, State> {
    render() {
        return <Layout>
            <Poster />
            <PageWrapper>
                <StyledSectionRow>
                    <VideoWithSubtitle />
                </StyledSectionRow>
                <StyledSectionRow>
                    <Links />
                </StyledSectionRow>
                <StyledSectionRow>
                    <RandomVideos />
                </StyledSectionRow>
            </PageWrapper>
        </Layout>
    }
}

