import { useQuery } from 'react-query';
import API from "../../../libs/api";
import {SeasonType} from "../../../types";

export const useGetSeasons = () => {
    const url = `${process.env.REACT_APP_API_URL}/storage`;

    const fetchData = async () => {
        const {success, data: {data}} = await API.get('/seasons');

        if (!success) {
            return [];
        }

        return data.map((season: SeasonType) => ({
            ...season,
            cover: `${url}${season.cover}`
        }));
    }

    return useQuery('getSeasons', fetchData);
}