import styled from "styled-components";

export const StyledSeason = styled.div`
  width: 100%;
  cursor: pointer;
  margin-bottom: 20px;
  img {
    width: 100%;
  }
  h3 {
    font-size: 18px;
  }
`;