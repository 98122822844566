import {useEffect, useRef} from "react";

export const usePrev = (value: any) => {
    const prevRef = useRef(value);

    useEffect(() => {
        return () => {
            prevRef.current = null;
        }
    }, []);

    return prevRef.current;
}