import React, {useMemo, useState} from 'react';
import {Breadcrumbs, BreadcrumbType} from "../../../components/Breadcrumbs";
import {PageLayout} from "../../../components/layout/PageLayout";
import {PageTitle} from "../../../components/title/PageTitle";
import styled from "styled-components";
import {get} from 'lodash';
import {useDelete, useEdit, useGetMenu} from "./api";
import {Select, SelectOptions} from "../../../components/form/select";
import {useGetArticles} from "../articles/api";
import {MenuItem} from "./MenuItem";
import {ArticleItem, TArticleItem} from "./ArticleItem";
import {AddForm, TFormItem} from "./AddForm";

const StyledMenus = styled.div`
  margin-top: 50px;
  display: flex;
  gap: 30px;
  .left-menus {
    flex: none;
    width: 200px;
  }
  .right-menus {
    flex-grow: 1;
  }
`;

const menuOptions: SelectOptions = [
    {
        text: 'Top menu',
        value: 'top-menu'
    },
    {
        text: 'Middle menu',
        value: 'middle-menu'
    },
    {
        text: 'Footer menu',
        value: 'footer-menu'
    },
    {
        text: 'Sidebar menu',
        value: 'sidebar-menu'
    }
]

export type TMenuItem = {
    id: string;
    menuId: string;
    title: string;
    url: string;
    order: string;
}

export const AdminMenu = () => {
    const breadcrumbs:BreadcrumbType[] = useMemo(() => {

        const list = [];

        list.push({
            title: 'Домашня сторінка',
            action: '/'
        });

        list.push({
            title: 'Адміністрування',
            action: '/admin'
        });

        list.push({
            title: 'Налаштування Меню',
        });

        return list;

    }, []);
    const {mutateAsync: editItem} = useEdit();
    const {mutateAsync: deleteItem} = useDelete();

    const [selectedMenu, setSelectedMenu] = useState(menuOptions[0].value);

    const {data: dataArticles, isFetching} = useGetArticles();
    const articles: TArticleItem[] = get(dataArticles, 'data', []);

    const {data, refetch} = useGetMenu(selectedMenu);
    const menuItems:TMenuItem[] = data || [];

    const onMenuChange = async (item: any) => {
        const payload = {
            id: item.id,
            menuId: selectedMenu,
            order: item.order,
            title: item.title,
            url: item.url
        };
        await editItem(payload);
        return;
    }

    const onAddArticleToMenu = async (article: TArticleItem) => {
        const payload = {
            menuId: selectedMenu,
            order: '0',
            title: article.title,
            url: `/articles/${article.id}`
        };
        await editItem(payload);
        await refetch();
    }

    const onDeleteItem = async (id: string) => {
        await deleteItem({
            id,
            menuId: selectedMenu
        });
        await refetch();
    }

    const onAddNewItem = async (item: TFormItem) => {
        const payload = {
            menuId: selectedMenu,
            order: item.order,
            title: item.title,
            url: item.url
        };
        await editItem(payload);
        await refetch();
    }

    return <PageLayout loading={isFetching}>
        <Breadcrumbs items={breadcrumbs} />
        <PageTitle style={{marginBottom: '30px'}} color='#f45542'>
            Налаштування Меню
        </PageTitle>

        <Select
            options={menuOptions}
            onChange={setSelectedMenu}
            label="Вибиріть меню"
        />

        <StyledMenus>
            <div className="left-menus">
                <PageTitle size="16px">Новий елемент</PageTitle>
                <AddForm onAdd={onAddNewItem} />
                <PageTitle size="16px">Записи</PageTitle>
                <div className="articles-list">
                    {articles.map((article) => {
                        return <ArticleItem
                            key={article.id}
                            item={article}
                            onAdd={onAddArticleToMenu}
                        />
                    })}
                </div>
            </div>
            <div className="right-menus">
                <PageTitle size="16px">Меню</PageTitle>
                {menuItems.map((item) => {
                    return <MenuItem
                        key={item.url}
                        onChange={onMenuChange}
                        onDelete={onDeleteItem}
                        item={item}
                    />
                })}
            </div>
        </StyledMenus>


    </PageLayout>
}