import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {isEqual} from 'lodash';
import {Button} from "../../../components/form/button";

const StyledItem = styled.div`
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  .textField {
    flex-grow: 1;
    padding: 5px 10px;
    font-size: 18px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .orderField {
    flex: none;
    width: 80px;
    padding: 5px 10px;
    font-size: 18px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

export type TArticleItem = {
    id: string;
    title: string;
};

type TProps = {
    onAdd: (item: TArticleItem) => void;
    item: TArticleItem;
};

export const ArticleItem = ({item, onAdd}: TProps) => {

    const [state, setState] = useState(item);

    useEffect(() => {
        setState(item);
    }, [item]);


    const onAddClick = () => {
        onAdd(item);
    }

    return <StyledItem>
        <div className="title">{state.title}</div>
        <div className="action">
            <Button
                onClick={onAddClick}
                text="Додати"
            />
        </div>
    </StyledItem>
}