import React from 'react';
import {StyledWrapper} from "./styles";
import {Header} from "../header";
import classNames from "classnames";
import {Loader} from "../loader";

type Props = {
    children: React.ReactNode;
    sidebar?: React.ReactNode;
    loading?: boolean;
}

export const Layout = ({children, sidebar, loading}: Props) => {
    return <div>
        <Header />
        <StyledWrapper>
            <div className={classNames({content: true, fullWidth: !sidebar})}>
                {loading ? <Loader /> : children }
            </div>
            {sidebar &&
                <div className="sidebar">{sidebar}</div>
            }
        </StyledWrapper>
    </div>
}