import {Pagination} from "../../../types";
import styled from "styled-components";
import classNames from "classnames";

type TProps = {
    data: Pagination,
    onChangePage?: (data: number) => void;
}

const StyledPagination = styled.div`
  padding: 20px 0;
  display: flex;
  gap: 10px;
  .prevPage, 
  .nextPage {
    cursor: pointer;
  }
  .isDisabled {
    opacity: .4;
    cursor: not-allowed;
  }
`;

export const TablePagination = ({data, onChangePage = () => {}}: TProps) => {

    const onPrev = () => {
        if (data.currentPage === 1 ) return false;
        onChangePage(data.currentPage-1);
    }

    const onNext = () => {
        if (data.currentPage === data.totalPages || data.totalPages === 0 ) return false
        onChangePage(data.currentPage+1);
    }

    return <StyledPagination>
        <div className={classNames({'prevPage': true, isDisabled: data.currentPage === 1})} onClick={onPrev}>Попередня</div>
        Сторінка {data.currentPage}
        <div className={classNames({'nextPage': true, isDisabled: data.currentPage === data.totalPages || data.totalPages === 0 })} onClick={onNext}>Наступна</div>
    </StyledPagination>
}