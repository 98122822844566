import React from 'react';
import {StyledVideoLink} from "./styles";
import {Button} from "../Button";
import {useNavigate} from "react-router-dom";

type TProps = {
    url: string;
    cover: string;
    title: string;
    text?: string;
}

export const MediaLink = ({
    cover,
    title,
    url,
    text
}: TProps) => {
    const navigate = useNavigate();

    const path = `${process.env.REACT_APP_API_URL}/storage`;

    const onClickHandler = () => {
        navigate(url);
    }

    return <StyledVideoLink>
        <img src={`${path}/${cover}`} alt=""/>
        <div className="link-content">
            <h3>{title}</h3>
            {text && <p>{text}</p>}
            <div className="link-content__control">
                <Button onClick={onClickHandler} content={'Переглянути'} />
            </div>
        </div>

    </StyledVideoLink>
}