import React, {useMemo} from 'react';
import {PageLayout} from "../../components/layout/PageLayout";
import {StyledVideosList} from "../season/styles";
import {get} from "lodash";
import {useGetVideosList} from "./api";
import {Video} from "./components/video";
import {Breadcrumbs, BreadcrumbType} from "../../components/Breadcrumbs";
import {StyledTitle} from "../../styles";
import {PageWrapper} from "../home/styles";
import {Layout} from "../../components/layout";

export const VideoList = () => {

    const {data, isLoading} = useGetVideosList();
    const videos = get(data, 'videos', []);

    const breadcrumbs:BreadcrumbType[] = useMemo(() => {

        const list: BreadcrumbType[] = [];

        list.push({
            title: 'Список відео',
        });

        list.push({
            title: 'Домашня сторінка',
            action: '/'
        });

        return list.reverse();

    }, []);

    return <Layout loading={isLoading}>
        <PageWrapper>
            <Breadcrumbs items={breadcrumbs} />

            <StyledTitle>Відео</StyledTitle>
            <StyledVideosList>
                {videos.map((video) => {
                    return <Video
                        video={video}
                        key={video.id}
                        isSeasonal={video.isSeasonal}
                    />
                })}
            </StyledVideosList>
        </PageWrapper>
    </Layout>
}