import styled from 'styled-components';

export const StyledHeader = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 40px;
    img {
        max-width: 150px;
    }
   span {
     color: #f45542;
     font-size: 26px;
     font-weight: bold;
   }
`;
