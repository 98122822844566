import React, {useMemo} from 'react';
import {Breadcrumbs, BreadcrumbType} from "../../components/Breadcrumbs";
import {PageLayout} from "../../components/layout/PageLayout";
import {PageTitle} from "../../components/title/PageTitle";

export const Admin = () => {

    const breadcrumbs:BreadcrumbType[] = useMemo(() => {

        const list = [];
        list.push({
            title: 'Адміністрування',
        });

        list.push({
            title: 'Домашня сторінка',
            action: '/'
        });

        return list.reverse();

    }, []);

    return <PageLayout loading={false}>
        <Breadcrumbs items={breadcrumbs} />
        <PageTitle style={{marginBottom: '30px'}} color='#f45542'>
            Адміністрування
        </PageTitle>
        <>test</>
    </PageLayout>
}