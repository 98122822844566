import styled from "styled-components";

type TProps = {
    columns?: number;
    gaps?: number;
    nowrap?: boolean;
};
export const StyledColumns = styled.div<TProps>`
  display: flex;
  gap: ${({gaps = 0}) => gaps}px;
  flex-wrap: ${({nowrap}) => nowrap ? 'nowrap': 'wrap'};
  justify-content: flex-start;
  width: 100%;
  > div {
    flex: 0 0 calc(100% / ${({columns = 0}) => columns} - ${({gaps = 0,columns = 0}) => gaps/columns*(columns-1)}px);
    @media (max-width: 900px) {
      flex: 0 0 calc(100% / ${props => 2} - ${({gaps = 0}) => gaps/3*2}px);
    }
    @media (max-width: 640px) {
      flex: 0 0 calc(100% / ${props => 2} - ${({gaps = 0}) => gaps/2}px);
    }
    @media (max-width: 420px) {
      flex: 0 0 100%;
    }
  }
`;