import React, {useEffect, useState} from 'react';
import {Layout} from "../../../components/layout";
import {TextField} from "../../../components/form/textField";
import {Formik} from "formik";
import * as Yup from 'yup';
import {Button} from "../../../components/form/button";
import {StyledContainer, StyledControls} from "./styles";
import {Title} from "../../../components/title";
import {FormRow} from "../../../components/form/textField/styles";
import {useAuth} from "../../../hooks/useAuth";
import {Link, useNavigate} from "react-router-dom";
import {useRegister} from "../api";
import {StyledError} from "../login/styles";

export const Register = () => {
    const {mutateAsync, isLoading} = useRegister();

    const [error, setError] = useState<null|string>(null);

    const {isAuthorised} = useAuth();
    const navigate = useNavigate();

    const initialValues = {
        email: '',
        password: '',
        confirm: '',
        firstName: '',
        lastName: ''
    };

    useEffect(() => {
        if (isAuthorised) {
            navigate('/');
        }
    }, [isAuthorised]);

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Не корректний Email')
            .required("Обов'язкове поле"),
        password: Yup.string()
            .min(8, 'Повинне бути 8 символів, або більше')
            .required("Обов'язкове поле"),
        confirm: Yup.string()
            .min(8, 'Повинне бути 8 символів, або більше')
            .oneOf([Yup.ref('password')], 'Паролі повинні співпадати')
            .required("Обов'язкове поле"),
        firstName: Yup.string()
            .required("Обов'язкове поле"),
        lastName: Yup.string()
            .required("Обов'язкове поле"),
    });

    const onSubmit = async (values: any) => {
        setError(null);
        const response = await mutateAsync(values);
        if (response.success) {
            navigate('/login');
        } else {
            setError(response.error as string);
        }
    }

    return <Layout>
        <StyledContainer>
            <Title>Реєстрація</Title>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ isSubmitting, submitForm, isValid }) => (
                    <form>
                        <FormRow>
                            <TextField
                                label={"Email"}
                                type="email"
                                name="email"
                                placeholder="Введіть Email"
                            />
                        </FormRow>

                        <FormRow>
                            <TextField
                                label={"Пароль"}
                                type="password"
                                name="password"
                                placeholder="Введіть пароль"
                            />
                        </FormRow>

                        <FormRow>
                            <TextField
                                label={"Перевірте свій пароль"}
                                type="password"
                                name="confirm"
                                placeholder="Введіть пароль ще раз"
                            />
                        </FormRow>

                        <FormRow>
                            <TextField
                                label={"Ім'я"}
                                type="text"
                                name="firstName"
                                placeholder="Введіть ім'я"
                            />
                        </FormRow>

                        <FormRow>
                            <TextField
                                label={"Прізвище"}
                                type="text"
                                name="lastName"
                                placeholder="Введіть прізвище"
                            />
                        </FormRow>


                        {error &&
                            <FormRow>
                                <StyledError>{error}</StyledError>
                            </FormRow>
                        }

                        <StyledControls className="login-controls">
                            <Link to="/login">Авторизація</Link>
                            <Button
                                text={"Зареєструватись"}
                                onClick={submitForm}
                                disabled={!isValid || isSubmitting}
                            />
                        </StyledControls>

                    </form>
                )}

            </Formik>
        </StyledContainer>
    </Layout>
}
