export default class LocalStorage {

    static set = (key: string, value: any) => {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static get = (key: string) => {
        try {
            const value = localStorage.getItem(key);
            return value ? JSON.parse(value) : value;
        } catch(e) {
            return {};
        }

    }

    static clear = () => {
        localStorage.clear();
    }

    static getCurrentUser = () => {
        const session = LocalStorage.get('session');
        return session ? session.user : {};
    }

    static getToken = () => {
        const session = LocalStorage.get('session');
        return session ? session.token : '';
    }
}