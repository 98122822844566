import styled from "styled-components";
import theme from "../../../theme";

export const StyledSelect = styled.div`
  margin-bottom: 10px;
  select {
    border: 1px solid #66c4cd;
    outline: none;
    padding: 5px 10px;
    font-size: 18px;
    border-radius: 3px;
    width: 100%;
  }

  label {
    cursor: pointer;
    display: block;
    margin-bottom: 5px;
    color: ${theme.colors.headerBackground};
    font-weight: 700;
  }
`;