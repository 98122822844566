import React, { useEffect, useState } from 'react';
import useDraggable from './useDraggable';

type TProps = {
    text: string;
    onClose: () => void;
};

export const Tooltip = ({ text, onClose }: TProps) => {
    const draggableRef = useDraggable();
    const [trans, setTrans] = useState('');
    const [processing, setProcessing] = useState(true);

    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
    const getTranslation = async (str: string) => {
        const options = {
            method: 'GET',
        };
        const result = await fetch(`https://deadlines.com.ua/?translate=${str}`, options);
        return result.text();
    };

    useEffect(() => {
        getTranslation(text.replace(/<[^>]*>/g, '')).then((t) => {
            setTrans(t);
            setProcessing(false);
        });
    }, [text]);

    return (
        <div className="tooltip">
            <div className="close" onClick={onClose}>
                X
            </div>
            <>
                <div className="original">{text}</div>
                <div className="original">{processing ? <>Опрацювання...</> : trans}</div>
            </>
        </div>
    );
};
