import {useQuery, UseQueryResult} from "react-query";
import API from "../../../../libs/api";

export const useGetRandom = (): UseQueryResult => {
    const fetchData = async () => {
        const {data, success} = await API.get(`/videos/random`);
        if (!success) {
            return [];
        }
        return data;
    }

    return useQuery(['getRandomVideos'], fetchData, {
        staleTime: 0
    });
}