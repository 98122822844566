import {useAuth} from "../../hooks/useAuth";
import React, {useMemo, useRef, useState} from "react";
import {useDeleteDictionary, useGetDictionaries, useUpdateDictionary} from "./api";
import {Button} from "../../components/form/button";
import {Breadcrumbs, BreadcrumbType} from "../../components/Breadcrumbs";
import {Layout} from "../../components/layout";
import {PageWrapper} from "../home/styles";
import {PageTitle} from "../../components/title/PageTitle";
import {Table} from "../../components/Table";
import {NoPermissions} from "../../components/NoPermissions";
import {Link} from "react-router-dom";
import {Modal} from "../../components/Modal";
import * as Yup from "yup";
import {Formik} from "formik";
import {FormRow} from "../../components/form/textField/styles";
import {TextField} from "../../components/form/textField";
import {StyledControls} from "../auth/login/styles";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";

export const List = () => {
    const {isAuthorised} = useAuth();
    const {mutateAsync} = useDeleteDictionary();
    const {data, isLoading, refetch} = useGetDictionaries(isAuthorised);
    const rows: any = data || [];

    const defaultTitle = 'Створити новий словник';
    const [activeTitle, setActiveTitle] = useState(defaultTitle);

    const [modalState, setModalState] = useState<{open: boolean, id?: string}>({
        open: false
    });

    const headers = [
        {
            title: 'Назва',
            field: 'title',
            render: (dictionary: any) => {
                return <><Link to={`/my-dictionaries/${dictionary.id}`}>{dictionary.title}</Link></>
            }
        },
        {
            title: 'Опис',
            field: 'description'
        },{
            title: 'Кількість слів',
            field: 'words'
        },
        {
            title: ' ',
            render: (field: any) => {

                return <StyledControls>
                    <Button
                        onClick={async () => {
                            setInitialValues(() => ({
                                title: field.title,
                                description: field.description,
                                id: field.id
                            }));
                            setActiveTitle(`Оновити: ${field.title}`);
                            setModalState({
                                open: true,
                            });
                        }}
                        text={"Оновити"}
                        type="edit"
                        icon={<EditIcon />}
                    />
                    <Button
                        onClick={async () => {
                            await mutateAsync(field.id);
                            await refetch();
                        }}
                        text={"Видалити"}
                        type="delete"
                        icon={<DeleteIcon />}
                    />
                </StyledControls>
            },
            width: '80px'
        }
    ];

    const breadcrumbs:BreadcrumbType[] = useMemo(() => {

        const list = [];
        list.push({
            title: 'Словники',
        });

        list.push({
            title: 'Домашня сторінка',
            action: '/'
        });

        return list.reverse();

    }, []);


    const {mutateAsync: saveDictionary, isLoading: isSaving} = useUpdateDictionary();
    const formRef: any= useRef();

    const intiST = {
        title: '',
        description: ''
    };

    const [initialValues, setInitialValues] = useState(intiST);

    const validationSchema = Yup.object({
        title: Yup.string()
            .required("Обов'язкове поле"),
    });

    const onSubmit = async (values: any) => {
       await saveDictionary(values);
       await refetch();
       setModalState({
           open: false
       });
       setInitialValues(intiST);
       setTimeout(() => {
           setActiveTitle(defaultTitle);
       }, 500);
    }

    return <Layout>
        <PageWrapper>
            <Breadcrumbs items={breadcrumbs} />
            <PageTitle
                style={{marginBottom: '30px'}}
                color='#f45542'
                action={isAuthorised ? <Button
                    onClick={async () => {
                        setModalState({
                            open: true
                        })
                    }}
                    text={"Створити словник"}
                    type="edit"
                /> : <></>}
            >
                Мої Словники
            </PageTitle>
            {isAuthorised ? <Table
                headers={headers}
                data={rows}
                isLoading={isLoading}
            /> : <NoPermissions />}

            <Modal
                title={activeTitle}
                onClose={() => {
                    setModalState({
                        open: false
                    });
                    setInitialValues(intiST);
                    setTimeout(() => {
                        setActiveTitle(defaultTitle);
                    }, 500);
                }}
                open={modalState.open}
                onSave={() => {
                    if (formRef.current) {
                        formRef.current.submitForm();
                    }
                }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    innerRef={formRef}
                >
                    {() => (
                        <form>
                            <FormRow>
                                <TextField
                                    label={"Назва"}
                                    type="text"
                                    name="title"
                                    placeholder="Назва"
                                />
                            </FormRow>

                            <FormRow>
                                <TextField
                                    label={"Опис"}
                                    type="text"
                                    name="description"
                                    placeholder="Опис"
                                />
                            </FormRow>
                        </form>
                    )}

                </Formik>
            </Modal>
        </PageWrapper>
    </Layout>
}