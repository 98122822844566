import classNames from 'classnames';
import React, {useState} from 'react';
import { StyledLine } from './styles';
import { Tooltip } from './Tooltip';
import img from '../../../../assets/img/flag.png'

type TProps = {
    startSeconds: number;
    text: string;
    isPlaying: boolean;
    onPlay: (time: number, index: number) => void;
    translationString?: string;
    onPause?: (time: number, index: number) => void;
    onWordClick: (w: string) => void;
    style?: any;
    isActive: boolean,
    lineIndex: number
};

export const Line = ({
    startSeconds,
    text,
    onPlay,
    isPlaying,
    translationString,
    onPause,
    onWordClick,
    style,
    isActive,
    lineIndex
}: TProps) => {

    const [translation, setTranslation] = useState<string | null>(null);

    const getClickedWord = (event: any) => {
        let clickedWord = '';
        const dcm: any = document;
        const x = event.clientX;
        const y = event.clientY;

        let range: Range;
        let textNode;
        let offset;

        if (dcm.caretPositionFromPoint) {
            const caret = dcm.caretPositionFromPoint(x, y);
            textNode = caret.offsetNode;
            offset = caret.offset;
        } else if (dcm.caretRangeFromPoint) {
            range = dcm.caretRangeFromPoint(x, y);
            textNode = range.startContainer;
            offset = range.startOffset;
        }

        if (textNode && textNode.nodeType === Node.TEXT_NODE) {
            const data = textNode.textContent;
            const words = data.split(/\s+/);
            let cumulativeLength = 0;
            for (let word of words) {
                cumulativeLength += word.length + 1;
                if (cumulativeLength > offset) {
                    clickedWord = word;
                    break;
                }
            }
        }
        return clickedWord;
    }

    const setActiveWord = (event: any) => {
        const paragraph = event.target;
        const word = getClickedWord(event);
        const originalText = paragraph.textContent;
        const highlightedText = originalText.replace(word, `<span class="highlight">${word}</span>`);
        paragraph.innerHTML = highlightedText;
    }

    const removeActive = (event: any) => {
        const highlightSpan = (event.target as any).querySelector('.highlight');
        if (highlightSpan) {
            highlightSpan.replaceWith(highlightSpan.textContent);
        }
    }

    return (
        <StyledLine className={classNames({ activeLine: isActive })} style={style}>
            <div
                className="text-line"
            >
                <div
                    className="text"
                    dangerouslySetInnerHTML={{__html: text}}
                    onClick={(event) => {
                        if (onPause) {
                            onPause(startSeconds+1, lineIndex);
                        }
                        onWordClick(getClickedWord(event));
                    }}
                    onMouseMove={setActiveWord}
                    onMouseLeave={removeActive}
                />

                {translationString && (
                    <div className="text" dangerouslySetInnerHTML={{ __html: translationString }} />
                )}
            </div>

            <div className="controls">
                <div
                    className={classNames({
                        button: true,
                        pause: isPlaying && isActive,
                        play: !isPlaying || !isActive,
                    })}
                    onClick={() => {
                        onPlay(lineIndex === 0 ? 0 : startSeconds, lineIndex);
                    }}
                />
                <div
                    style={{ backgroundImage: `url(${img})` }}
                    className="button translate"
                    onClick={async () => {
                        setTranslation(text.replace(/<[^>]*>/g, ''));
                    }}
                />

                {translation && (
                    <Tooltip
                        text={translation}
                        onClose={() => {
                            setTranslation(null);
                        }}
                    />
                )}
            </div>
        </StyledLine>
    );
};
