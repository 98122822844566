import API from "../../../libs/api";
import {userLoginType} from "../../../types";
import {useMutation} from "react-query";
import LocalStorage from "../../../libs/storage";

export const useLogin = () => {
    const sendData = async (payload: userLoginType) => {
        const resp = await API.post(`/auth/login`, payload);

        if (resp.success) {
            LocalStorage.set('session', resp.data);
            return {
                success: true
            }
        } else {
            return {
                success: false,
                error: 'Не вірні дані'
            }
        }

    }
    return useMutation(sendData);
}

export const useRegister = () => {
    const sendData = async (payload: any) => {
        const resp = await API.post(`/auth/register`, payload);

        if (resp.success) {
            return {
                success: true
            }
        } else {
            return {
                success: false,
                error: 'Не вірні дані'
            }
        }

    }
    return useMutation(sendData);
}