import React from 'react';
import {StyledPoster, StyledPosterWrapper} from "./styles";
import img from '../../../../assets/img/posters/poster3.jpg';

export const Poster = () => {
    return <StyledPosterWrapper>
       <StyledPoster image={img} />
        <div className="poster-content">
            <h1>Від кіно до розмови: вивчай англійську легко та захоплююче!</h1>
        </div>
    </StyledPosterWrapper>
}