import React, {useState} from "react";
import {ModalContext} from "./index";
import {ModalState} from "./types";
import {Modal} from "../components/Modal";

type TProps = {
    children: React.ReactNode
}

export const ModalProvider = ({children}: TProps) => {

    const defaultModalState: ModalState = {
        open: false,
        onSave: () => {},
        onClose: () => {},
        title: '',
        children: <></>,
    }
    const [state, setState] = useState<ModalState>(defaultModalState)

    const setModal = (values: Partial<ModalState>) => {
        setState({
            ...state,
            ...values
        });
    }

    return <ModalContext.Provider value={{
        state,
        setModal
    }}>
        {/*<Modal {...state} />*/}
        {children}
    </ModalContext.Provider>
}