import React from 'react';
import {StyledTitle} from "./styles";

export type QuizWord = {
    word: string;
    translation: string[];
};

type TProps = {
    words: QuizWord[]
};

export const Quiz = ({words}: TProps) => {

    return <>
        <StyledTitle>Hello</StyledTitle>
    </>
}