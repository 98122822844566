import LocalStorage from "../storage";

class API {
    static ENDPOINT = process.env.REACT_APP_API_URL;
    static get = async (url: string, options?: any) => {
        try {
            const headers = new Headers();

            headers.append('Content-Type', 'application/json');
            headers.append('auth-token', LocalStorage.getToken());

            const resp = await fetch(`${API.ENDPOINT}${url}`, {
                method: 'GET',
                headers: headers,
            });
            const data = !options?.text ? await resp.json() : await resp.text();
            return {
                success: true,
                data
            }
        } catch(e) {
            return {
                success: false
            }
        }
    }

    static delete = async (url: string, options?: any) => {
        try {
            const headers = new Headers();

            headers.append('Content-Type', 'application/json');
            headers.append('auth-token', LocalStorage.getToken());
            const resp = await fetch(`${API.ENDPOINT}${url}`, {
                method: 'DELETE',
                headers: headers,
            });
            const data = !options?.text ? await resp.json() : await resp.text();
            return {
                success: true,
                data
            }
        } catch(e) {
            return {
                success: false
            }
        }
    }

    static getText = async (url: string) => {
        try {
            const resp = await fetch(url);
            const data = await resp.text();
            return {
                success: true,
                data
            }
        } catch(e) {
            return {
                success: false
            }
        }
    }

    static post = async (url: string, payload: any) => {

        try {
            const headers = new Headers();

            headers.append('Content-Type', 'application/json');
            headers.append('auth-token', LocalStorage.getToken());

            const endpoint = `${API.ENDPOINT}${url}`;

            const resp = await fetch(endpoint, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(payload),
            });

            return await resp.json();

        } catch(e) {
            return {
                success: false
            }
        }

    }
}

export default API;