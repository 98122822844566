import React from 'react';
import {StyledPageLayout} from "./styles";
import {Loader} from "../loader";
import {Header} from "../header";
import {SidebarMenu} from "../SidebarMenu";
import {Breadcrumbs} from "../Breadcrumbs";
import styled from "styled-components";

type TProps = {
    children: React.ReactNode;
    loading?: boolean;
}
const StyledWrapper = styled.div`
  max-width: 1000px;
  padding-right: 20px;
`;
export const PageLayout = ({children, loading}: TProps) => {
    return <StyledPageLayout>
        <Header />
        <div className="page-content">
            <div>
                <SidebarMenu />
            </div>
            <div className="layout-content">
                <StyledWrapper>
                    {loading ? <Loader /> : children }
                </StyledWrapper>
            </div>
        </div>

    </StyledPageLayout>
}