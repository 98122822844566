import styled from "styled-components";

type TProps = {
    image: string;
};

export const StyledPosterWrapper = styled.div`
  position: relative;
  height: 400px;
  z-index: 2;
  overflow: hidden;
  .poster-content {
    text-align: center;
    h1 {
      margin: 0;
      color: #fff;
      position: absolute;
      top: 50%;
      z-index: 10;
      left: 50%;
      transform: translate(-50%, -20%);
      font-size: 42px;
      padding: 10px;
      background: #3d6778;
      @media (max-width: 900px) {
        transform: translate(-50%, -50%);
      }
    }
  }
`;
export const StyledPoster = styled.div<TProps>`
  height: 400px;
  width: 100%;
  background: url(${(props: any) => props.image}) no-repeat center center;
  background-size: cover;
  background-position: 0 100%;
  filter: blur(2px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;