import styled from 'styled-components';
import theme from "../../../../theme";

export const StyledLine = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #a2cdd1;
    position: relative;
    .text-line {
        flex-grow: 1;
        cursor: pointer;
    }

    .text {
        opacity: 1;
        flex-grow: 1;
        color: ${theme.colors.inactiveTextColor};
        span {
          position: relative;
          &:after {
            content: "";
            position: absolute;
            bottom: -3px;
            height: 2px;
            background: ${theme.colors.inactiveTextColor};
            left: 0;
            transition: all .3s;
            width: 100%;
          }
          
        }
    }

    &.activeLine {
        .text {
            color: ${theme.colors.textColor};
          span {
            &:after {
              background: ${theme.colors.textColor};
            }
          }
        }
    }

    .controls {
        flex: none;
        width: 70px;
        display: flex;
    }

    .button {
        /* border: 1px solid #ccc;*/
        width: 30px;
        height: 30px;
        margin-left: 10px;
        flex: none;
        cursor: pointer;
    }

    .play {
        position: relative;

        &:after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 5px;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 0 5px 9px;
            border-color: transparent transparent transparent #28b9c7;
        }
    }

    .pause {
        position: relative;

        &:after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 5px;
            content: '';
            width: 2px;
            height: 10px;
            background: #28b9c7;
        }

        &:before {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 9px;
            content: '';
            width: 2px;
            height: 10px;
            background: #28b9c7;
        }
    }

    .translate {
        position: relative;
        background-size: cover;
        width: 14px;
        height: 10px;
        margin: 10px;
    }

    .tooltip {
        position: absolute;
        width: 200px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 1px 0 4px -1px;
        padding: 10px;
        z-index: 1;
        right: 45px;
    }

    .close {
        padding: 0 12px;
        text-align: right;
        border-bottom: 1px solid #e2e0e0;
        padding-bottom: 7px;
        margin: 0 -10px;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .original {
        padding: 0 12px;
        border-bottom: 1px solid #e2e0e0;
        padding-bottom: 7px;
        margin: 0 -10px;
        margin-bottom: 10px;
        cursor: pointer;
    }
`;
