import styled from "styled-components";

export const StyledVideoLink = styled.div`
  background: #fff;
  margin-bottom: 10px;
  padding: 4px;
 /* cursor: pointer;*/
  border: 1px solid #d0d7d9;
  img {
    width: 100%;
  }
  .link-content {
    padding: 5px;
    &__control {
     /* text-align: right;*/
    }
  }
  h3 {
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
`;