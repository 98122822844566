import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {StyledHeader, StyledTrigger} from "./styles";
import {useAuth} from "../../hooks/useAuth";
import Dropdown from "../Dropdown";
import LocalStorage from "../../libs/storage";
import {useGetMenuOrdered} from "../../hooks/useGetMenuOrdered";

export const Header = () => {
    const {isAuthorised} = useAuth();
    const menu = useGetMenuOrdered('top-menu');
    const navigate = useNavigate();

    const items = menu.map((item) => ({
        title: item.title,
        onClick: () => {
            navigate(item.url);
        }
    }));

    let mobileItems: any = [];

    if (isAuthorised) {
        mobileItems = [
            {
                title: 'Профіль',
                onClick: () => {
                    navigate('/');
                },
                className: 'mobile'
            },
            {
                title: 'Завершити роботу',
                onClick: () => {
                    LocalStorage.clear();
                    document.location.reload();
                },
                className: 'mobile'
            }
        ]
    } else {
        mobileItems = [
            {
                title: 'Авторизація',
                onClick: () => {
                    navigate('/login');
                },
                className: 'mobile'
            }
        ]
    }

    return <StyledHeader>
        <div className="header-container">
            <div className="menu-container">
                <Link to="/" className="logo">
                    CineMentor
                </Link>
                <div className="mobile">
                    <Dropdown
                        icon={<div className={"menu-icon"}>Меню</div>}
                        items={[...items, ...mobileItems]}
                    />
                </div>
                <div className="desktop">
                    {menu.map((item) => {
                        return <Link key={item.id} to={item.url} className="head-link">
                            {item.title}
                        </Link>
                    })}
                </div>


            </div>
            <div className="desktop">
                {!isAuthorised && <Link to="/login" className="loginLink">Авторизація</Link>}
                {isAuthorised && <WelcomeUser />}
            </div>
        </div>
    </StyledHeader>
}

const WelcomeUser = () => {
    const {user} = useAuth();
    const navigate = useNavigate();

    const items = [{
            title: 'Профіль',
            onClick: () => {
                navigate('/');
            }
        },
        {
            title: 'Завершити роботу',
            onClick: () => {
                LocalStorage.clear();
                document.location.reload();
            }
        }
    ];

    return <>
        <Dropdown
            icon={<>
                <StyledTrigger>Вітаю, {user.firstName} {user.lastName}!</StyledTrigger>
            </>}
            items={items}
        />
    </>

}