import React, {useEffect} from 'react';
import {useAuth} from "../../hooks/useAuth";
import {useNavigate} from "react-router-dom";

type Props = {
    children: React.ReactNode
}

export const PrivateRoute = ({children}: Props) => {
    const {isAuthorised} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthorised) {
            navigate('/');
        }
    }, [isAuthorised]);

    if (!isAuthorised) {
        return <></>
    }
    return <>
        {children}
    </>;
}

export const AdminRoute = ({children}: Props) => {
    const {isAuthorised, isAdmin} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthorised || !isAdmin) {
            navigate('/');
        }
    }, [isAuthorised, isAdmin]);

    if (!isAuthorised || !isAdmin) {
        return <></>
    }
    return <>
        {children}
    </>;
}