const theme = {
    colors: {
        mainBackground: '#bee2e2',
        headerBackground: '#3d6778',
        menuItemColor: '#46788a',
        menuItemColorHover: '#18272d',
        actionColor: '#f45542',
        mainGray: '#737373',
        textColor: '#020101',
        inactiveTextColor: '#8f8f8f',
        borderInputs: '#66c4cd'
    },
    spacing: {
        small: '8px',
        medium: '16px',
        large: '24px',
    },
};

export default theme;