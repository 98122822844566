import styled from "styled-components";
import {ButtonSize} from "./index";
import theme from "../../theme";

type StyledSectionProps = {
    background?: string;
    hoverBackground?: string;
    size?: ButtonSize
};

export const StyledSection = styled.div<StyledSectionProps>`
  background: ${props => props.background || '#d94b3a'};
  color: #fceae7;
  padding: 5px;
  display: inline-block;
  border-radius: 2px;
  cursor: pointer;
  font-size: 14px;
  transition: all .2s;
  margin-top: 10px;
  &:hover {
    background: ${props => props.hoverBackground || '#ab3b2e'};
  }
  &.button-medium {
    padding: 10px 20px;
    font-size: 16px;
  }
  
  &.button-large {
    padding: 10px 20px;
    font-size: 22px;
  }
`;

export const StyledArrow = styled.div`
  .error-content {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: ${theme.colors.actionColor};
    cursor: pointer;
    position: relative;
    display: inline-block;
    transition: all .3s;

    &:hover {
      background: #cb4535;
    }

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      position: absolute;
      top: calc(50% + 3px);
      left: calc(50% - 4px);
      transform: rotate(45deg) translate(-50%, -50%);
    }

    &:after {
      content: '';
      width: 20px;
      height: 2px;
      background: #ffffff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;