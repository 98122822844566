import LocalStorage from "../libs/storage";

export const useAuth = () => {
    const isAuthorised = !!LocalStorage.getToken();
    const user = LocalStorage.getCurrentUser();
    const isAdmin: boolean = user ?  user.isAdmin : false;

    return {
        isAuthorised,
        user,
        isAdmin
    }

}