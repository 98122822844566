import {useMutation, useQuery, UseQueryResult} from "react-query";
import API from "../../../libs/api";
import {ArticleType} from "../../../types";

export const useGetArticles = (): UseQueryResult => {
    const fetchData = async () => {
        const {data, success} = await API.get(`/admin/articles`);
        if (!success) {
            return [];
        }
        return data;
    }

    return useQuery(['useGetArticles'], fetchData, {
        staleTime: 0
    });
}

export const useGetArticle = (id?: string): UseQueryResult => {
    const fetchData = async () => {
        const {data, success} = await API.get(`/admin/article/${id}`);
        if (!success) {
            return [];
        }
        return data;
    }

    return useQuery(['useGetArticle', id], fetchData, {
        staleTime: 0,
        enabled: !!id && id !== 'new'
    });
}

export const useEdit = () => {
    const sendData = async (payload: ArticleType) => {
        const resp = await API.post(`/admin/article`, payload);

        if (resp.success) {
            return {
                success: true
            }
        } else {
            return {
                success: false,
                error: 'Щось пішло не так'
            }
        }

    }
    return useMutation(sendData);
}

export const useDelete = () => {
    const sendData = async (id: string) => {
        const resp = await API.delete(`/admin/article/${id}`);

        if (resp.success) {
            return {
                success: true
            }
        } else {
            return {
                success: false,
                error: 'Щось пішло не так'
            }
        }

    }
    return useMutation(sendData);
}