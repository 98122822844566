import styled from "styled-components";
import theme from "../../../../theme";
import {Link} from "react-router-dom";

export const StyledLinks = styled.div`
  .content {
    width: 100%;
    h1 {
      margin-bottom: 20px;
    }
  }
`;

export const StyledLink = styled(Link)`
  cursor: pointer;
  color: ${theme.colors.headerBackground};
  font-size: 18px;
  margin-bottom: 5px;
  text-decoration: none;
  display: block;
`;