import React, {useMemo} from 'react';
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import {PageLayout} from "../../../components/layout/PageLayout";
import {PageTitle} from "../../../components/title/PageTitle";
import {useArticlesState} from "./useArticlesState";
import {Table} from "../../../components/Table";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "../../../components/form/button";
import styled from "styled-components";
import {get} from 'lodash';
import {useDelete, useGetArticles} from "./api";

const StyledAction = styled.div`
  margin-bottom: 20px;
`;

const StyledControls = styled.div`
  display: flex;
  gap: 20px;
`;

export const AdminArticles = () => {
    const navigate = useNavigate();
    const {breadcrumbs} = useArticlesState();
    const {mutateAsync, isLoading} = useDelete();
    const {data, isFetching, refetch} = useGetArticles();

    const articles = get(data, 'data', []);

    const headers = [
        {
            title: 'Id',
            field: 'id'
        },
        {
            title: 'Заголовок',
            field: 'title'
        },
        {
            title: '',
            render: (field: any) => {

                return <StyledControls>
                    <Button onClick={() => {
                        navigate(`${field.id}`);
                    }} text={'Відкрити'} />

                    <Button onClick={async () => {
                                await mutateAsync(field.id);
                                await refetch();
                            }}
                            text={'Видалити'}
                            type="delete"
                    />

                </StyledControls>
            },
            width: '220px'
        }
    ];

    return <PageLayout loading={isFetching}>
        <Breadcrumbs items={breadcrumbs} />
        <PageTitle style={{marginBottom: '30px'}} color='#f45542'>
            Навчальні матеріали
        </PageTitle>

        <StyledAction>
            <Button
                text="Новий запис"
                onClick={() => {
                    navigate('/admin/articles/new')
                }}
            />
        </StyledAction>

        <>
            <Table
                headers={headers}
                data={articles}
            />
        </>
    </PageLayout>
}