import {Layout} from "../../components/layout";
import {PageWrapper} from "../home/styles";
import React from "react";
import styled from "styled-components";

export const StyledH = styled.h1`
  text-align: center;
  color: #565656;
`;
export const NotFound = () => {
    return <Layout>
        <PageWrapper>
            <StyledH>Нічого не знайдено :(</StyledH>
        </PageWrapper>
    </Layout>
}