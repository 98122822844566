import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import theme from "../../theme";

const StyledWrapper = styled.div`
  a {
    color: ${theme.colors.borderInputs}
  }
`;
export const NoPermissions = () => {
    return <StyledWrapper>
        <Link to="/login">Авторизуйтесь</Link> для продовження.
    </StyledWrapper>
}