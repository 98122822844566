import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {isEqual} from 'lodash';
import {Button} from "../../../components/form/button";

const StyledItem = styled.div`
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  .checkbox {
    flex: none;
    width: 20px;
    input {
      cursor: pointer;
      border: 1px solid #ccc;
    }
  }
  .textField {
    flex-grow: 1;
    padding: 5px 10px;
    font-size: 18px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .orderField {
    flex: none;
    width: 80px;
    padding: 5px 10px;
    font-size: 18px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

export type TMenuItem = {
    id: string;
    url: string;
    title: string;
    order?: any,
};

type TProps = {
    onChange: (item: TMenuItem) => void;
    onDelete: (id: string) => void;
    item: TMenuItem;
};

export const MenuItem = ({onChange, item, onDelete}: TProps) => {

    const [state, setState] = useState(item);

    useEffect(() => {
        setState(item);
    }, [item]);


    const onChangeInput = (e: any) => {
        const {name, value} = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));

        onChange({
            ...state,
            [name]: value
        })
    }

    const onDeleteHandler = () => {
        onDelete(item.id);
    }

    // console.log(state.isChecked);
    return <StyledItem>
        <input
            className="textField"
            type="text"
            name="title"
            placeholder="Заголовок"
            value={state.title}
            onChange={onChangeInput}
        />
        <input
            className="textField"
            type="text"
            name={"url"}
            placeholder={"Url"}
            value={state.url}
            onChange={onChangeInput}
        />
        <input
            className="orderField"
            type="text"
            name={"order"}
            placeholder={"Order"}
            value={state.order}
            onChange={onChangeInput}
        />
        <div className="action">
            <Button
                onClick={onDeleteHandler}
                text="Видалити"
                type="delete"
            />
        </div>
    </StyledItem>
}