import React, {CSSProperties} from "react";
import {StyledCheckbox} from "./styles";

type TProps = {
    name: string;
    onChange: (isChecked: boolean) => void;
    className?: string;
    style?: CSSProperties;
    labelText: string;
}

export const Checkbox = (props: TProps) => {
    const {
        style,
        name,
        className,
        onChange,
        labelText
    } = props;

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.checked);
    }

    return <StyledCheckbox>
        <input
            id={`checkbox-${name}`}
            className={className}
            name={name}
            type="checkbox"
            style={style}
            onChange={onChangeHandler}
        />
        <label htmlFor={`checkbox-${name}`}>{labelText}</label>
    </StyledCheckbox>
}