import React, {RefObject, useEffect, useMemo, useRef, useState} from "react";
import {List} from "react-virtualized";
import {useGetSubtitles, useGetVideo} from "../../pages/video/api";
import srtParser2 from "srt-parser-2";
import {VideoPlayer} from "./components/VideoPlayer";
import {TranslationModal} from "../Modal/TranslationModal";
import {signal} from "@preact/signals-react";
import {StyledVideoWrapper} from "./styles";
import {Line} from "./components/Line";

type SubtitleType = {
    startSeconds: number;
    endSeconds: number;
    text: string;
};

const time = signal(0);

type TProps = {
    listHeight: number;
    rowHeight: number;
    videoId: string;
}

export const VideoWithSubtitles = ({
    listHeight,
    rowHeight,
    videoId
}: TProps) => {

    const listRef: RefObject<List> = React.createRef();
    const [isPlaying, setIsPlaying] = useState(false);
    const playerRef: any = useRef();
    const [subtitles, setSubtitles] = useState<SubtitleType[]>([]);
    const {data: vdata, isLoading} = useGetVideo(videoId as string);
    const video = vdata || null;
    const [seconds, setSeconds] = useState(0);
    const [activeLine, setActiveLine] = useState(0);
    const [translateModal, setTranslateModal] = useState({
        open: false,
        word: '',
    });

    const {data} = useGetSubtitles(vdata?.subtitles);

    useEffect(() => {
        if (data) {
            const parser = new srtParser2();
            const srt_array = parser.fromSrt(data as any);
            setSubtitles(srt_array);
        }
    }, [data]);

    const changeTime = (value: number) => {
        time.value = value;
        setSeconds(value);
    }

    const [scrollToIndex, setScrollToIndex] = useState(0);
    const [scrollToAlignment, setScrollToAlignment] = useState('auto');

    const scrollToRow = (index: number, alignment = 'auto') => {
        setScrollToIndex(() => index);
        setScrollToAlignment(() => alignment);
    };

    const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0 });

    const onScroll = ({ scrollTop }: {scrollTop: number}) => {
        setScrollPosition({ scrollTop });
    };

    useEffect(() => {
        let index = -1;
        subtitles.forEach((st, key) => {
            if (st.startSeconds <= seconds &&  seconds >=st.endSeconds) {
                index = key;
            }
        });
        setActiveLine( index + 1);
    }, [subtitles, seconds]);

    const renderer = ({index, key, style}: any) => {
        const st = subtitles[index];
        return (
            <Line
                lineIndex={index}
                isActive={activeLine === index}
                style={style}
                isPlaying={isPlaying}
                key={key}
                startSeconds={st.startSeconds}
                text={st.text}
                onPlay={(t, index) => {
                    setActiveLine(index);
                    if (playerRef.current) {
                        playerRef.current.pause();
                        if (
                            !isPlaying ||
                            time.value < st.startSeconds ||
                            time.value > st.endSeconds
                        ) {
                            playerRef.current.currentTime = t;
                            playerRef.current.play();
                        }
                    }
                }}
                onPause={(t, index) => {
                    playerRef.current.currentTime = t;
                    playerRef.current.pause();
                    setIsPlaying(false);
                    setActiveLine(index)
                }}
                onWordClick={(word: string) => {
                    setTranslateModal({
                        open: true,
                        word
                    })
                }}
            />
        );
    }

    useEffect(() => {
        const startVisibleIndex = Math.floor(scrollPosition.scrollTop / rowHeight);
        const endVisibleIndex = startVisibleIndex + Math.floor(listHeight / rowHeight) - 1;
        const isVisible = activeLine >= startVisibleIndex && activeLine <= endVisibleIndex;
        if ( !isVisible) {
            scrollToRow(activeLine,  'start');
        }
    }, [seconds]);

    return <>
        <StyledVideoWrapper>
                {video && <div className="video">
                    <VideoPlayer
                        videoRef={playerRef}
                        url={video.url}
                        onPlaying={(t) => {
                            changeTime(t);
                        }}
                        onPlay={() => {
                            setIsPlaying(true);
                        }}
                        onPause={() => {
                            setIsPlaying(false);
                        }}
                    />
                </div>}
                <div className="subtitles">
                    <List
                        ref={listRef}
                        width={600}
                        height={listHeight}
                        rowRenderer={renderer}
                        rowCount={subtitles.length}
                        rowHeight={rowHeight}
                        scrollToIndex={scrollToIndex}
                        scrollToAlignment={scrollToAlignment as any}
                        onScroll={onScroll}
                    />
                </div>
        </StyledVideoWrapper>
        {translateModal.open && <TranslationModal
            {...translateModal}
            onClose={() => {
                setTranslateModal({
                    open: false,
                    word: ''
                })
            }}
        />}

    </>
}