import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  margin-top: 50px;
  padding: 40px 20px;
  background: #fff;
  border-radius: 4px;
`;

export const StyledControls = styled.div`
  display: flex;
  justify-content: end;
  /*margin-top: 30px;*/
  gap: 20px;
  &.login-controls {
    display: flex;
    align-items: center;
    gap: 20px;
    a {
      text-decoration: none;
      color: #28b9c7;
    }
  }
`;

export const StyledError = styled.p`
  display: block;
  font-size: 14px;
  color: #d94b3a;
  font-weight: bold;
  position: absolute;
`;