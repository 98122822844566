import React, {useMemo, useRef, useState} from 'react';
import {Breadcrumbs, BreadcrumbType} from "../../components/Breadcrumbs";
import {PageTitle} from "../../components/title/PageTitle";
import {Table} from "../../components/Table";
import {Button} from "../../components/form/button";
import {Layout} from "../../components/layout";
import {PageWrapper} from "../home/styles";
import {emptyPagedResponse, useDeleteWord, useGetDictionary, useUpdateWord} from "./api";
import {useAuth} from "../../hooks/useAuth";
import {NoPermissions} from "../../components/NoPermissions";
import {useParams} from "react-router-dom";
import {isArray} from "lodash";
import {Modal} from "../../components/Modal";
import {Formik} from "formik";
import {FormRow} from "../../components/form/textField/styles";
import {TextField} from "../../components/form/textField";
import * as Yup from "yup";
import {StyledControls} from "../auth/login/styles";

import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";

export const Dictionary = () => {
    const {isAuthorised} = useAuth();
    const {dictionaryId} = useParams();
    const limit = 15;
    const [page, setPage] = useState(1);
    const [modalState, setModalState] = useState<{open: boolean, id?: string}>({
        open: false
    });

    const {mutateAsync: deleteWord, isLoading: isDeleting} = useDeleteWord(dictionaryId || '0');
    const {data, isLoading, refetch} = useGetDictionary(dictionaryId,{page, limit});
    const {mutateAsync: updateWord} = useUpdateWord();

    //TODO: move to separated component or hook
    const formRef: any= useRef();
    const intiST = {
        word: '',
        translation: '',
        transcription: '',
        original: ''
    };

    const [initialValues, setInitialValues] = useState(intiST);
    const validationSchema = Yup.object({
        word: Yup.string()
            .required("Обов'язкове поле"),
    });

    // @ts-ignore
    const { rows, dictionary,  ...pagination} = data || {...emptyPagedResponse, dictionary: {}}

    const headers = [
        {
            title: 'Слово',
            field: 'word'
        },
        {
            title: 'Транскрипція',
            field: 'transcription'
        },
        {
            title: 'Переклад',
            field: 'translation',
            render: (field: any) => {
                return <>
                    {isArray(field.translation) ? field.translation.join(', ') : field.translation}
                </>
            },
        },
        {
            title: '',
            render: (field: any) => {

                return <StyledControls>
                    <Button
                        onClick={async () => {
                            setInitialValues(() => ({
                                word: field.word,
                                translation: field.translation,
                                transcription: field.transcription,
                                original: field.word
                            }))
                            setModalState({
                                open: true,
                            });
                        }}
                        text={"Оновити"}
                        type="edit"
                        icon={<EditIcon />}
                    />
                    <Button
                        onClick={async () => {
                            await deleteWord(field.word);
                            await refetch();
                        }}
                        text={"Видалити"}
                        type="delete"
                        icon={<DeleteIcon />}
                    />
                </StyledControls>
            },
            width: '80px'
        }
    ];

    const breadcrumbs:BreadcrumbType[] = useMemo(() => {

        const list = [];

        list.push({
            title: dictionary.title,
        });

        list.push({
            title: 'Словники',
            action: '/my-dictionaries'
        });

        list.push({
            title: 'Домашня сторінка',
            action: '/'
        });

        return list.reverse();

    }, [dictionary]);

    const onSubmit = async (value: any) => {
        await updateWord({
            ...value,
            id: dictionaryId
        });
        setModalState({open: false});
        await refetch();
    }

    return <Layout>
        <PageWrapper>
        <Breadcrumbs items={breadcrumbs} />
        <PageTitle
            style={{marginBottom: '10px'}}
            size="39px"
            color='#f45542'
            action={<Button
                onClick={async () => {
                    setModalState({
                        open: true
                    })
                }}
                text={"Додати слово"}
                type="edit"
            />}
        >
            {dictionary.title}
        </PageTitle>
        <PageTitle
            style={{marginBottom: '30px'}}
            size="16px"
            color='#424242'
        >
            {dictionary.description}
        </PageTitle>
            {isAuthorised ? <Table
                headers={headers}
                data={rows}
                pagination={pagination}
                onChangePage={(page) => {
                    setPage(page)
                }}
                isLoading={isLoading}
            /> : <NoPermissions />}

        </PageWrapper>

        <Modal
            title={!!initialValues.original.length ? 'Редагувати слово' : 'Додати слово'}
            onClose={() => {
                setModalState({
                    open: false
                });
                setInitialValues(intiST);
            }}
            open={modalState.open}
            onSave={() => {
                if (formRef.current) {
                    formRef.current.submitForm();
                }
            }}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                innerRef={formRef}
            >
                {() => (
                    <form>
                        <FormRow>
                            <TextField
                                label={"Слово"}
                                type="text"
                                name="word"
                                placeholder="Слово"
                            />
                        </FormRow>

                        <FormRow>
                            <TextField
                                label={"Переклад"}
                                type="text"
                                name="translation"
                                placeholder="Переклад"
                            />
                        </FormRow>

                        <FormRow>
                            <TextField
                                label={"Транскрипція"}
                                type="text"
                                name="transcription"
                                placeholder="Транскрипція"
                            />
                        </FormRow>
                    </form>
                )}

            </Formik>
        </Modal>

    </Layout>
}