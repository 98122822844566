import styled from "styled-components";

type StyledSectionProps = {
    background?: string;
    hoverBackground?: string;
    disabled?: boolean;
};

export const StyledButton = styled.div<StyledSectionProps>`
  background: ${props => props.background || '#d94b3a'};
  color: #fff;
  padding: 10px 10px;
  display: inline-block;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: all .2s;
  opacity: ${props => props.disabled ? '0.5' : '1'};
  &:hover {
    background: ${props => props.hoverBackground || '#ab3b2e'};
  }
  &.delete {
    background: #d94b3a;
    &:hover {
      background: #ab3b2e;
    }
  }
`;

export const StyledIconButton = styled.div`
  cursor: pointer;
  fill: #3d6778;
  font-size: 19px;
  transition: all .3s;
  width: 30px;
  &:hover {
    opacity: .7;
  }
`;