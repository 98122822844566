import React from 'react';
import { Field } from 'formik';

import {StyledError, StyledInput} from "./styles";

type Props = {
    type?: string;
    name: string;
    className?: string;
    placeholder?: string;
    label?: string;
};

export const TextField = ({
    type,
    name,
    className,
    placeholder,
    label
}: Props) => {

    return <StyledInput className={className}>
        {label && <label htmlFor={name}>{label}</label>}
        <Field
            name={name}
            type={type || 'text'}
            placeholder={placeholder}
        />
        <StyledError name={name} component="div" />
    </StyledInput>
}