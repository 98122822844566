import React from 'react';
import {StyledSelect} from "./styles";

type SelectOption = {
    text: string;
    value: string;
}
export type SelectOptions = SelectOption[];

type TProps = {
    options: SelectOptions;
    name?: string;
    className?: string;
    onChange: (value: string) => void;
    label?: string;
    placeholder?: string;
}

export const Select = (props: TProps) => {
    const {
        options,
        name,
        className,
        label,
        onChange,
        placeholder
    } = props;
    return <StyledSelect>
        {!!label && <label htmlFor={`id-${name}`}>{label}</label>}
        <select
            id={`id-${name}`}
            name={name}
            className={className}
            onChange={(e) => {
                onChange(e.target.value);
            }}
            placeholder={placeholder}
        >
            {options.map((option) => {
                return <option key={option.value} value={option.value}>{option.text}</option>
            })}
        </select>
    </StyledSelect>
}