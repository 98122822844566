import React from 'react';
import {StyledBreadcrumbsList, StyledItem} from "./styles";
import {useNavigate} from "react-router-dom";

export type BreadcrumbType = {
    title: string;
    action?: string;
}

export type BreadcrumbsType = {
    items: BreadcrumbType[];
}

export const Breadcrumbs = ({items}: BreadcrumbsType) => {
    const navigate = useNavigate();

    return <StyledBreadcrumbsList>
        {items.map((item, key) => {
            return <StyledItem
                key={key}
                disabled={!item.action}
                onClick={() => {
                    if (item.action) {
                        navigate(item.action);
                    }
                }}
            >{item.title}</StyledItem>
        })}
    </StyledBreadcrumbsList>
}