import {useMutation, useQuery, UseQueryResult} from "react-query";
import API from "../../libs/api";
import {ArticleType, PagedResponse} from "../../types";

export type Words = {
    transcription: string;
    translation: string[];
    word: string;
};

export const emptyPagedResponse: PagedResponse<Words> = {
    currentPage: 1,
    limit: 10,
    rows: [],
    totalItems: 0,
    totalPages: 0
}

export const useGetDictionary = (dictionaryId: any, payload: {page: number, limit: number} = {page: 1, limit: 10}): UseQueryResult<PagedResponse<Words>> => {
    const fetchData = async ():Promise<PagedResponse<Words>>  => {
        const {page, limit} = payload;

        const {data, success} = await API.get(`/dictionary/${dictionaryId}?page=${page}&limit=${limit}`);
        if (!success) {
            return emptyPagedResponse;
        }
        return data.data || emptyPagedResponse;
    }
    return useQuery(['getTranslations', payload], fetchData, {
        staleTime: 0
    });
}


export const useDeleteWord = (dictionaryId: string) => {
    const sendData = async (word: string) => {
        const resp = await API.delete(`/dictionary/${dictionaryId}/${word}`);

        if (resp.success) {
            return {
                success: true
            }
        } else {
            return {
                success: false,
            }
        }

    }
    return useMutation(sendData);
}

export const useGetDictionaries = (enabled: boolean) => {
    const fetchData = async ():Promise<PagedResponse<Words>>  => {
        const {data, success} = await API.get(`/dictionary/list`);
        return data.data || [];
    }
    return useQuery(['getDictionaries'], fetchData, {
        staleTime: 0,
        enabled
    });
}

export const useDeleteDictionary = () => {
    const sendData = async (dictionaryId: string) => {
        const resp = await API.delete(`/dictionary/list/${dictionaryId}`);
        if (resp.success) {
            return {
                success: true
            }
        } else {
            return {
                success: false,
            }
        }

    }
    return useMutation(sendData);
}

export const useUpdateDictionary = () => {
    const sendData = async (payload: any) => {
        const resp = await API.post(`/dictionary/list`, payload);
        if (resp.success) {
            return {
                success: true
            }
        } else {
            return {
                success: false,
                error: 'Щось пішло не так'
            }
        }

    }
    return useMutation(sendData);
}

export const useUpdateWord = () => {
    const sendData = async (payload: any) => {
        const {id, ...rest} = payload;
        const resp = await API.post(`/dictionary/${id}`, rest);
        if (resp.success) {
            return {
                success: true
            }
        } else {
            return {
                success: false,
                error: 'Щось пішло не так'
            }
        }

    }
    return useMutation(sendData);
}