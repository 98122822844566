import React, {useMemo} from 'react';
import {Layout} from "../../components/layout";
import {Season} from "./components/Season";
import {useGetSeasons} from "./api";
import {SeasonType} from "../../types";
import {useParams} from "react-router-dom";
import {Breadcrumbs, BreadcrumbType} from "../../components/Breadcrumbs";
import {StyledTitle} from "../../styles";
import {PageWrapper} from "../home/styles";
import {Columns} from "../../components/columns";

export const Seasons = () => {
    const {videoListId} = useParams();
    const {data, isLoading} = useGetSeasons();
    const seasons = data || [];

    const breadcrumbs:BreadcrumbType[] = useMemo(() => {

        const list: BreadcrumbType[] = [];

        if (videoListId) {
            list.push({
                title: 'Сезони',
            });
        }

        list.push({
            title: 'Список відео',
            action: `/videos/`,
        });

        list.push({
            title: 'Домашня сторінка',
            action: '/'
        });

        return list.reverse();

    }, [videoListId]);

    return <Layout loading={isLoading}>
        <PageWrapper>
        <Breadcrumbs items={breadcrumbs} />
        <StyledTitle>Теорія Великого Вибуху</StyledTitle>
        <Columns columns={5} gaps={20}>
            {seasons.map((season: SeasonType) => {
                return <Season
                    key={season.id}
                    season={season}
                    videoListId={videoListId as string}
                />
            })}
        </Columns>
        </PageWrapper>
    </Layout>
}