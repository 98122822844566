import API from "../../../libs/api";
import {VideoType} from "../../../types";
import {useQuery, UseQueryResult} from "react-query";

type VideosServerType = {
    videos: VideoType[];
}

export const useGetVideosList = (): UseQueryResult<VideosServerType> => {
    const url = `${process.env.REACT_APP_API_URL}/storage`;

    const fetchData = async () => {
        const {success, data: {data}} = await API.get(`/videos`);
        if (!success) {
            return [];
        }

        return {
            ...data,
            videos: data.map((video: VideoType) => ({
                ...video,
                cover: `${url}${video.cover}`
            }))
        };
    }

    return useQuery(['useGetVideosList'], fetchData);
}