import React, {useEffect, useState} from 'react';
import {ModalContent, ModalFooter, ModalHeader, StyledModal} from "./styles";
import {Button} from "../form/button";
import {ModalState} from "../../context/types";

export const Modal = ({
   title,
   open,
   onSave,
   saveText,
   onClose,
   children,
    buttonType
}: ModalState) => {

    const [modalOpen, setOpenModal] = useState(open);
    const [visible, setVisible] = useState(false);
    const [rendered, setRendered] = useState(false);

    useEffect(() => {
        setOpenModal(open);
    }, [open]);

    useEffect(() => {
        if (modalOpen) {
            setRendered(true);
            setTimeout(() => {
                setVisible(true);
            }, 10);
        } else {
            setVisible(false);
            setTimeout(() => {
                setRendered(false);
            }, 300);
        }
    }, [modalOpen]);

    const onCloseHandler = () => {
        setOpenModal(false);
        onClose();
    }

    return <>{ rendered && <StyledModal visible={visible ? visible : undefined}>
        <div className="content">
            <ModalHeader>
                <h3>{title}</h3>
                <div className="modal-close" onClick={onCloseHandler} />
            </ModalHeader>
            <ModalContent>
                {children}
            </ModalContent>
            <ModalFooter>
                <Button type={buttonType || 'edit'} onClick={onSave} text={saveText || 'Зберегти'}/>
            </ModalFooter>
        </div>
        <div className="overlap" />
    </StyledModal>}</>
}