import {TMenuItem} from "../pages/admin/menu";

export const useSplitArray = (array: any[], countsOfArray: number = 1, splittingByX = false) => {
    const splitArray = (arr: TMenuItem[]) => {
        if (array.length < countsOfArray) {
            return [array];
        }
        const compiledArray = []
        const length = Math.ceil(arr.length / countsOfArray);
        for (let i = 0; i < countsOfArray; i++) {
            const start = i * length;
            const end = start + length + 1;
            compiledArray.push(arr.slice(start, end));
        }
        return compiledArray;
    }

    const splitArrayByX = (arr: TMenuItem[]) => {
        const compiledArray: any[][] = new Array(countsOfArray).fill([]).map(() => []);
        const length = countsOfArray;
        if (array.length < countsOfArray) {
            return array.map((item) => [item]);
        }

        for (let i = 0; i < countsOfArray; i++) {
            const start = i * length;
            const end = start + length;
            arr.slice(start, end).forEach((item, index) => {
                compiledArray[index].push(item);
            });
        }

        return compiledArray;
    }

    return splittingByX ? splitArrayByX(array) : splitArray(array);
}