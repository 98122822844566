import styled from "styled-components";
import theme from "../../theme";

export const StyledBreadcrumbsList = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 30px;
  gap: 20px;
  flex-wrap: wrap;
`;

export const StyledItem = styled.div<{disabled: boolean}>`
  cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
  font-size: 14px;
  position: relative;
  opacity: ${({disabled}) => disabled ? '0.4' : '1'};
  color: ${theme.colors.headerBackground};
  transition: .3s;
  &:last-child {
    &:after {
      display: none;
    }
  }
  &:after {
    content: '|';
    position: absolute;
    right: -12px;
  }
`;