import React from 'react';
import {StyledTitle} from "./styles";

type Props = {
    children: React.ReactNode;
    style?: any,
    size?: string,
}
export const Title = ({children, style, size}: Props) => {
    return <StyledTitle style={style} size={size}>
        <h1 className="title-content">
            <span>{children}</span>
        </h1>
    </StyledTitle>
}