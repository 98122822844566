import React from 'react';
import {StyledTable} from "./styles";
import {Pagination} from "../../types";
import {TablePagination} from "./components/TablePagination";

type TableHeader = {
    title: string;
    field?: string;
    render?: (field: any) => void;
    width?: string;
};

type TProps = {
    headers: TableHeader[],
    data: any[],
    pagination?: Pagination,
    onChangePage?: (page: number) => void;
    isLoading?: boolean;
}

export const Table = ({headers, data, pagination, onChangePage, isLoading}: TProps) => {

    const renderField = (fieldData: any, field: string | undefined) => {

        if (!field) {
            return ''
        }

        return fieldData[field];

    }

    return <StyledTable>

        {!!pagination && <TablePagination
                data={pagination}
                onChangePage={onChangePage}
            />
        }

        <table>
            <thead>
            <tr>
                {headers.map(({title, field, width}, key) => {
                    return <th
                        className="table-header__content"
                        key={key}
                        style={{width: width ? width : `auto`}}
                    >
                        {title}
                    </th>
                })}
            </tr>
            </thead>
            {isLoading && !data.length ?
                <tbody>
                    <tr>
                        <td>Завантаження...</td>
                    </tr>
                </tbody>:
                <tbody>
                    {data.map((fieldData: any, key) => {
                        return <tr className="table-row" key={key}>
                            {headers.map(({field, width, render}, key) => {
                                return <td
                                    key={key}
                                    className="table-row__content"
                                >
                                    {render ? render(fieldData) : renderField(fieldData, field)}
                                </td>
                            })}
                        </tr>
                    })}
            </tbody>}
        </table>
    </StyledTable>
}