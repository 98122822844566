import React, {useMemo} from 'react';
import {Breadcrumbs, BreadcrumbType} from "../../components/Breadcrumbs";
import {PageTitle} from "../../components/title/PageTitle";
import {PageLayout} from "../../components/layout/PageLayout";
import {useGetArticles} from "./api";
import {get} from 'lodash';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {ArticleType} from "../../types";
import theme from "../../theme";
import {PageWrapper} from "../home/styles";
import {Layout} from "../../components/layout";
import {Columns} from "../../components/columns";

const StyledList = styled.div`
  margin: 20px 0;
  a {
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
    color: ${theme.colors.headerBackground};
    transition: all .3s;
    &:hover {
      color: ${theme.colors.menuItemColorHover};
    }
  }
`;

const StyledLink = styled(Link)`
  display: block;
`;
export const Articles = () => {
    const {data, isFetching} = useGetArticles();
    const articles = get(data, 'data', []);

    const breadcrumbs:BreadcrumbType[] = useMemo(() => {

        const list = [];
        list.push({
            title: 'Навчальні матеріали',
        });

        list.push({
            title: 'Домашня сторінка',
            action: '/'
        });

        return list.reverse();

    }, []);

    return <Layout loading={isFetching}>
        <PageWrapper>
            <Breadcrumbs items={breadcrumbs} />
            <PageTitle style={{marginBottom: '30px'}} color='#f45542'>
                Навчальні матеріали
            </PageTitle>
            <StyledList>
                <Columns columns={3} gaps={20}>
                    {articles.map((article: ArticleType) => {
                        return <div>
                            <StyledLink to={`/articles/${article.id}`}>- {article.title}</StyledLink>
                        </div>
                    })}
                </Columns>
            </StyledList>
        </PageWrapper>
    </Layout>
}