import {useQuery, UseQueryResult} from "react-query";
import API from "../../libs/api";
import {ArticleType} from "../../types";

export const useGetArticles = (): UseQueryResult => {
    const fetchData = async () => {
        const {data, success} = await API.get(`/articles`);
        if (!success) {
            return [];
        }
        return data;
    }

    return useQuery(['useGetPublicArticles'], fetchData, {
        //staleTime: 0
    });
}

export const useGetArticle = (id?: string): UseQueryResult => {
    const fetchData = async () => {
        const {data, success} = await API.get(`/articles/${id}`);
        if (!success) {
            return [];
        }
        return data;
    }

    return useQuery(['useGetPublicArticle', id], fetchData, {
        //staleTime: 0,
        enabled: !!id && id !== 'new'
    });
}
