import React from 'react';
import {StyledColumns} from "./styles";
import classNames from "classnames";

type TProps = {
    children: React.ReactNode;
    columns: number;
    gaps?: number;
    className?: string;
    nowrap?: boolean;
    style?: {[key: string]: string};
};

export const Columns = ({children, className, nowrap, style = {}, columns = 1, gaps = 5}: TProps) => {
    return <StyledColumns
        style={style}
        columns={columns || undefined}
        gaps={gaps || undefined}
        className={classNames(className)}
        nowrap={nowrap}
    >
        {children}
    </StyledColumns>
}