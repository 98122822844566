import API from "../../../libs/api";
import {SeasonType, VideoType} from "../../../types";
import {useQuery, UseQueryResult} from "react-query";

type SeasonServerType = {
    videos: VideoType[];
    season: SeasonType;
}

export const useGetSeason = (id: string): UseQueryResult<SeasonServerType> => {
    const url = `${process.env.REACT_APP_API_URL}/storage`;

    const fetchData = async () => {
        const {success, data: {data}} = await API.get(`/seasons/${id}`);

        if (!success) {
            return [];
        }

        return {
            ...data,
            videos: data.videos.map((season: SeasonType) => ({
                ...season,
                cover: `${url}${season.cover}`
            }))
        };
    }

    return useQuery(['getSeason', id], fetchData);
}