import React from 'react';
import {SeasonType} from "../../../../types";
import {StyledSeason} from "./styles";
import {useNavigate} from "react-router-dom";

type Props = {
    season: SeasonType;
    videoListId: string;
};

export const Season = ({season, videoListId}: Props) => {
    const navigate = useNavigate();

    const onSeasonClick = () => {
        navigate(`/videos/${videoListId}/seasons/${season.id}`);
    }

    return <StyledSeason onClick={onSeasonClick}>
        <img src={season.cover} alt=""/>
        <h3>{season.title}</h3>
    </StyledSeason>
}