import styled from "styled-components";

export const StyledTable = styled.div`
  max-width: 100%;
  @media(max-width: 900px) {
    overflow-x: auto;
    overflow-y: visible;
  }
 
  table {
    border-top: 1px solid #e1e1e1;
    border-collapse: collapse;
    border-spacing: 0; /* This removes spacing between table cells */
    width: 100%;
    border-left: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
  }

  thead {
    background: #e7e7e7;
    position: sticky;
    top: 38px;
    @media(max-width: 900px) {
      top: 0;
    }
  }
  
  tbody {
    tr {
      padding: 5px 0;
      border-bottom: 1px solid #e1e1e1;
      background: #f6f6f6;
    }
  }
  th {
    text-align: left;
  }
  th, td {
    min-width: 50px;
    padding: 10px;
  }
`;