import React, {useEffect, useState} from 'react';
import {Layout} from "../../../components/layout";
import {TextField} from "../../../components/form/textField";
import {Formik} from "formik";
import * as Yup from 'yup';
import {Button} from "../../../components/form/button";
import {StyledContainer, StyledControls, StyledError} from "./styles";
import {Title} from "../../../components/title";
import {FormRow} from "../../../components/form/textField/styles";
import {useLogin} from "../api";
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "../../../hooks/useAuth";

export const Login = () => {
    const {isAuthorised} = useAuth();
    const navigate = useNavigate();

    const [error, setError] = useState<null|string>(null);
    const {mutateAsync} = useLogin();
    const initialValues = {
        email: '',
        password: ''
    };

    useEffect(() => {
        if (isAuthorised) {
            navigate('/');
        }
    }, [isAuthorised]);

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Не корректний Email')
            .required("Обов'язкове поле"),
        password: Yup.string()
            .min(8, 'Повинне бути 8 символів, або більше')
            .required("Обов'язкове поле"),
    });

    const onSubmit = async (values: any) => {
        setError(null);
       const response = await mutateAsync(values);
       if (response.success) {
           navigate('/');
       } else {
           setError(response.error as string);
       }
    }

    return <Layout>
        <StyledContainer>
            <Title>Авторизація</Title>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ isSubmitting, submitForm, isValid }) => (
                    <form>
                        <FormRow>
                            <TextField
                                label={"Email"}
                                type="email"
                                name="email"
                                placeholder="Введіть Email"
                            />
                        </FormRow>

                        <FormRow>
                            <TextField
                                label={"Пароль"}
                                type="password"
                                name="password"
                                placeholder="Введіть пароль"
                            />
                        </FormRow>

                        {error &&
                            <FormRow>
                                <StyledError>{error}</StyledError>
                            </FormRow>
                        }

                        <StyledControls className="login-controls">
                            <Link to="/register">Реєстрація</Link>
                            <Button
                                text={"Авторизуватись"}
                                onClick={submitForm}
                                disabled={!isValid || isSubmitting}
                            />
                        </StyledControls>

                    </form>
                )}

            </Formik>
        </StyledContainer>
    </Layout>
}
