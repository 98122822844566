import React from 'react';
import {StyledMenu} from "./styles";
import {Link, NavLink} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import {useGetMenuOrdered} from "../../hooks/useGetMenuOrdered";

export const SidebarMenu = () => {
    const {isAdmin} = useAuth();
    const menu = useGetMenuOrdered('sidebar-menu');

    return <StyledMenu>
        <ul>
            {
                menu.map((item) => {
                    return  <li key={item.id}>
                        <NavLink end to={item.url}>{item.title}</NavLink>
                    </li>
                })
            }
            { isAdmin && <>
                <li>
                    <NavLink end to="/admin">Адміністрування</NavLink>
                    <ul>
                        <li>
                            <NavLink to="/admin/articles">Навчальні Матеріали</NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/menu">Налаштування Меню</NavLink>
                        </li>
                    </ul>
                </li>
            </>
            }
        </ul>
    </StyledMenu>;
}