import React from 'react';
import {StyledVideo} from "./styles";
import {VideoType} from "../../../../types";
import {useNavigate} from "react-router-dom";

type Props = {
    video: VideoType;
    isSeasonal?: boolean;
}
export const Video = ({video, isSeasonal}: Props) => {

    const navigate = useNavigate();
    const onSeasonClick = () => {
        if (isSeasonal) {
            navigate(`/videos/${video.id}/seasons`);
        } else {
            navigate(`/videos/${video.id}`);
        }
    }

    return <StyledVideo onClick={onSeasonClick}>
        <div className="img-container">
            <img src={video.cover} alt={video.title}/>
        </div>
        <h3>{video.title}</h3>
    </StyledVideo>
}