import API from "../../../libs/api";
import {VideoType} from "../../../types";
import {useQuery, UseQueryResult} from "react-query";

export const useGetVideo= (id: string): UseQueryResult<VideoType> => {
    const url = `${process.env.REACT_APP_API_URL}/storage`;

    const fetchData = async () => {
        const {success, data: {data}} = await API.get(`/videos/${id}`);
        if (!success) {
            return {};
        }
        return {
            ...data,
            cover: `${url}${data.cover}`,
            subtitles: `${url}${data.subtitles}`,
            url: `${url}${data.url}`,
        };
    }
    return useQuery(['video', id], fetchData, {
        //staleTime: 0
    });
}

export const useGetSubtitles = (path?: string) => {

    const fetchData = async () => {
        const {success, data} = await API.getText(path as string);
        if (!success) {
            return {};
        }
        return data;
    }
    return useQuery(['subtitle', path], fetchData, {
        enabled: !!path,
        //staleTime: 0
    });
}