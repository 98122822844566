import React from 'react';
import { StyledHeader } from './styles';

type TProps = {
    cover: string;
    title: string;
};
export const Header = ({ cover, title }: TProps) => {
    return (
        <StyledHeader>
            <img src={cover} alt="" />
            <span>{title}</span>
        </StyledHeader>
    );
};
