import styled from "styled-components";

export const StyledVideos = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  .rv-title {
    font-size: 24px;
  }
  
  .videos-list {
    display: flex;
    width: 100%;
    &__left {
      flex-grow: 1;
    }
  }
`;