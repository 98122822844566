import React from 'react';
import {StylePagedTitle} from "./styles";

type Props = {
    children: React.ReactNode;
    style?: any,
    size?: string,
    color?: string,
    action?: React.ReactNode;
}
export const PageTitle = ({children, style, size, color, action}: Props) => {
    return <StylePagedTitle style={style} size={size}>
        <h1 className="title-content">
            <div className="title-content-inner" style={{color: color ? color : 'inherit'}}>
                <div className="child-title">
                    {children}
                </div> {action || <></>}
            </div>
        </h1>
    </StylePagedTitle>
}