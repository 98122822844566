import styled from "styled-components";
import theme from "../../theme";

export const StyledTitle = styled.div<{size?: string}>`
  margin: 5px 0;
  margin-bottom: 15px;
  text-align: center;
  display: block;
  width: 100%;
  position: relative;
  .title-content {
    position: relative;
    display: inline-block;
    font-size: ${({size}) => size ? size : '29px' };
    span {
      display: inline-block;
      padding: 5px 30px;
      color: #f45542;
      font-size: ${({size}) => size ? size : '29px' };
      position: relative;
      background: #ffffff;
      z-index: 1;
      @media (max-width: 900px) {
        padding: 5px;
      }
    }
  }
  &:before {
    content: "";
    height: 2px;
    background: ${theme.colors.headerBackground};
    width: 100%;
    position: absolute;
    top: 55%;
    left: 0;
  }
`;

export const StylePagedTitle = styled.div<{size?: string}>`
  margin: 5px 0;
  margin-bottom: 15px;
  text-align: left;
  display: block;
  width: 100%;
  position: relative;
  .title-content {
    position: relative;
    display: inline-block;
    font-size: ${({size}) => size ? size : '29px' };
    width: 100%;
  }
  .title-content-inner {
    padding: 5px 0;
    color: ${theme.colors.headerBackground};
    font-size: ${({size}) => size ? size : '29px' };
    position: relative;
    background: #ffffff;
    z-index: 1;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    align-items: center;
  }
  
  .child-title {
    min-width: 20px;
    min-height: 10px;
  }
`;