import styled from "styled-components";

export const StyledVideo = styled.div`
  width: 300px;
  cursor: pointer;
  .img-container {
    width: 100%;
    min-height: 165px;
      img {
          max-height: 320px;
      }
  }
`;