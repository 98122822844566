import styled from "styled-components";

export const StyledTitle = styled.h1`
  font-size: 32px;
  color: #f45542;
  text-align: center;
`;

export const StyledSection = styled.div`
  margin-bottom: 20px;
  display: flex;             
  gap: 25px;
  justify-content: center;
  img {
    max-width: 500px;
    width: 100%;
    min-height: 280px;
  }
  
`;
export const PageWrapper = styled.div`
  padding: 10px 20px;
  padding-top: 30px;
  max-width: 1400px;
  margin: 0 auto;
`;

export const StyledSectionRow = styled.div`
  margin-bottom: 60px;
`;