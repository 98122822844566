import { useRef, useEffect } from 'react';

const useDraggable = () => {
    const ref: any = useRef(null);
    let isDragging = false;
    let offsetX: any;
    let offsetY: any;

    const onMouseMove = (event: any) => {
        if (!isDragging) return;
        ref.current.style.left = `${event.clientX - offsetX}px`;
        ref.current.style.top = `${event.clientY - offsetY}px`;
    };

    const onMouseUp = () => {
        isDragging = false;
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
    };
    const onMouseDown = (event: any) => {
        isDragging = true;

        offsetX = event.clientX - ref.current.offsetLeft;
        offsetY = event.clientY - ref.current.offsetTop;
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    useEffect(() => {
        if (!ref.current) return;
        ref.current.addEventListener('mousedown', onMouseDown);
        // eslint-disable-next-line consistent-return
        return () => {
            if (!ref.current) return;

            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
            ref.current.removeEventListener('mousedown', onMouseDown);
        };
    }, [ref]);

    return ref;
};

export default useDraggable;
