import React, { useEffect } from 'react';
import styled from "styled-components";

type TProps = {
    url: string;
    onPlaying: (time: number) => void;
    videoRef: React.Ref<any>;
    onPause: () => void;
    onPlay: () => void;
};

const StyledContainer = styled.div`
  width: 100%;
  video {
    width: 100%;
  }
`;

export const VideoPlayer = ({ url, onPlaying, videoRef, onPause, onPlay }: TProps) => {
    useEffect(() => {
        const video = document.querySelector('#video');

        function handleEvent(event: any) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onPlaying(video.currentTime);
        }

        if (video) {
            video.addEventListener('timeupdate', handleEvent);
            video.addEventListener('pause', onPause);
            video.addEventListener('playing', onPlay);
        }
        return () => {
            if (video) {
                video.removeEventListener('timeupdate', handleEvent);
                video.removeEventListener('pause', onPause);
                video.removeEventListener('playing', onPlay);
            }
        };
    }, []);

    useEffect(() => {
        const video: any = document.querySelector('#video');
        if (video) {
            video.pause();
            video.load();
        }
    }, [url]);

    return (
        <StyledContainer>
            <video ref={videoRef} id="video" controls preload="metadata">
                <source src={url} type="video/mp4" />
            </video>
        </StyledContainer>
    );
};
