import React, {useCallback, useEffect, useState} from 'react';
import {Modal} from "./index";
import {TabType} from "../Tabs";
import {Formik} from "formik";
import {FormRow} from "../form/textField/styles";
import {TextField} from "../form/textField";
import {useTranslations} from "../../api/translations";
import styled from "styled-components";
import {get, isEqual} from 'lodash';
import {Checkbox} from "../form/checkbox";
import {Select, SelectOptions} from "../form/select";
import {DictionaryDropDown} from "./styles";
import {useGetDictionaries, useUpdateWord} from "../../pages/dictionary/api";
import {useAuth} from "../../hooks/useAuth";
import {usePrev} from "../../hooks/usePrev";
import {Link} from "react-router-dom";
import {Button} from "../form/button";

import { ReactComponent as UpdateIcon } from "../../assets/icons/update.svg";

const StyledWrapper = styled.div`
  min-height: 300px;
  .word {
    font-weight: bold;
  }
  .word-translation {
    display: flex;
    .delimeter {
      margin: 0 5px;
    }
  }
  .edit-form {
    margin-top: 30px;
  }
  .word-examples {
    margin-top: 20px;
  }
  .example {
    display: flex;
    gap: 20px;
    font-size: 14px;
    margin-bottom: 10px;
    > div {
      width: 50%;
      span {
        font-weight: bold;
      }
    }
  }
  
  .dictionary-wrap {
    margin-top: 30px;
  }
`;

type TProps = {
    open: boolean;
    word: string;
    onClose: () => void;
}
export const TranslationModal = ({
    open,
    word,
    onClose,
}: TProps) => {
    const {isAuthorised} = useAuth();
    const initialValues = {};
    const {data, isFetching} = useTranslations(word);

    const {mutateAsync: updateWord} = useUpdateWord();

    const translations = get(data, 'data.translations', []);
    const transcription = get(data, 'data.transcription', []);
    const examples: {source: string, target: string}[] = get(data, 'data.examples', []);
    const [toDictionary, setToDictionary] = useState(false);

    const [dictionariesOptions, setDictionariesOptions] = useState<SelectOptions>([]);
    const {data: dataDictionaries, isLoading, refetch} = useGetDictionaries(isAuthorised && toDictionary);
    const dictionaries: any = dataDictionaries || [];

    const [selectedDictionary, setSelectedDictionary] = useState<number|null>(null);

    const prevDictionaries = usePrev(dictionaries);
    useEffect(() => {
        if (!isEqual(dictionaries, prevDictionaries) || (!dictionariesOptions.length && dictionaries.length)) {
            setDictionariesOptions(() => dictionaries.map((dict: any) => ({
                text: dict.title,
                value: dict.id
            })));
            setSelectedDictionary(dictionaries[0].id);
        }

    }, [dictionaries, isLoading]);

    const onSubmit = (values: any) => {
        console.log(values);
    }

    const getTabs = useCallback(() => {
        return  [{
            title: 'Переклад',
            content: <>
                {isFetching ? <>Завантаження</> :
                    <>
                        <div className="word-translation">
                            <div className="word">{word}</div>
                            <div className="delimeter">-</div>
                            {transcription && <>
                                <div className="delimeter">{transcription}</div>
                                <div className="delimeter">-</div>
                            </>}
                            <div className="translation">
                                {translations.join(', ')}
                            </div>
                        </div>
                        <div className="word-examples">
                            {examples.map(({source, target}, i: number) => {
                                return <div className="example" key={i}>
                                    <div className="source" dangerouslySetInnerHTML={{ __html: source.replace(
                                                new RegExp(word.trim(), 'gi'),
                                                '<span>$&</span>'
                                            ) }}/>
                                    <div className="target" >{target}</div>
                                </div>
                            })}
                        </div>
                    </>
                }
            </>
        }, {
            title: 'Редагування',
            content: <>
                <div className="edit-form">
                    <Formik
                        initialValues={initialValues}
                        /*validationSchema={validationSchema}*/
                        onSubmit={onSubmit}
                    >
                        {({ isSubmitting, submitForm, isValid }) => (
                            <form>
                                <FormRow>
                                    <TextField
                                        type="text"
                                        name="word"
                                        placeholder="Слово"
                                    />
                                </FormRow>

                                <FormRow>
                                    <TextField
                                        type="text"
                                        name="transcription"
                                        placeholder="Транскрипцію"
                                    />
                                </FormRow>

                                <FormRow>
                                    <TextField
                                        type="text"
                                        name="translation"
                                        placeholder="Переклад"
                                    />
                                </FormRow>

                            </form>
                        )}

                    </Formik>
                </div>
            </>
        }] as TabType[]
    }, [isFetching, translations, examples])

    return <Modal
        title="Переклад"
        open={open}
        onSave={async () => {
            if (toDictionary) {
                await updateWord({
                    word,
                    translation: translations,
                    transcription,
                    toDictionary: true,
                    id: selectedDictionary
                });
            }
            onClose();
        }}
        onClose={onClose}
        saveText={toDictionary ? 'Додати до словника' : 'Закрити'}
    >
        <StyledWrapper>
            {isFetching ? <>Завантаження</> :
                <>
                    <div className="word-translation">
                        <div className="word">{word}</div>
                        <div className="delimeter">-</div>
                        {transcription && <>
                            <div className="delimeter">{transcription}</div>
                            <div className="delimeter">-</div>
                        </>}
                        <div className="translation">
                            {translations.join(', ')}
                        </div>
                    </div>
                    <div className="word-examples">
                        {examples.map(({source, target}, i: number) => {
                            return <div className="example" key={i}>
                                <div className="source" dangerouslySetInnerHTML={{ __html: source.replace(
                                        new RegExp(word.trim(), 'gi'),
                                        '<span>$&</span>'
                                    ) }}/>
                                <div className="target" >{target}</div>
                            </div>
                        })}
                    </div>

                    <div className="dictionary-wrap">
                        <Checkbox
                            name="toDictionary"
                            onChange={setToDictionary}
                            labelText="Додати до словника"
                        />

                        <DictionaryDropDown>
                            {toDictionary && <>
                                {!!dictionariesOptions.length && <Select
                                        label={"Оберіть словник"}
                                        options={dictionariesOptions}
                                        onChange={(id) => setSelectedDictionary(+id)}
                                        placeholder="Оберіть словник"
                                    /> }

                                <div style={{display: 'flex', gap: '20px'}}>
                                    <Link to="/my-dictionaries" target="__blank">Створити словник</Link>
                                    <Button
                                        onClick={async () => {
                                            await refetch();
                                        }}
                                        text={'Оновити'}
                                        icon={<UpdateIcon />}
                                    />

                                </div>
                                    </>
                            }
                        </DictionaryDropDown>
                    </div>
                </>
            }
        </StyledWrapper>
    </Modal>
}