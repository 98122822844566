import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.div`
  background-color: transparent;
  cursor: pointer;
  &:hover {
    color: gray;
  }
`;

type TDropDownProps = {
    show?: boolean;
    isRender?: boolean;
}

const DropdownContent = styled.div<TDropDownProps>`
  opacity: ${props => (props.show ? 1 : 0)}; /* Use opacity for smooth fade-in/fade-out */
  display: ${props => (props.isRender ? 'block' : 'none')};
  position: absolute;
  top: 45px;
  right: 0;
  background-color: #3d6778;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 200px;
  transform: translateY(${props => (props.show ? '2px' : '-8px')});
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out; /* Transition opacity */
`;

const DropdownItem = styled.button`
  background-color: transparent;
  padding: 12px 16px;
  width: 100%;
  text-align: left;
  border: none;
  display: block;
  cursor: pointer;
  color: #fff;
  font-size: 16px;

  &:hover {
    background-color: #2d4c59;
  }
`;

export type DropdownItemType = {
    title: string;
    onClick: () => void;
    className?: string;
}

type TProps = {
    icon: React.ReactNode;
    items: DropdownItemType[];
}

const Dropdown = ({
   icon,
   items
}: TProps) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [renderDropdown, setRenderDropdown] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleDocumentClick = (e: any) => {
            // @ts-ignore
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                toggleDropdown(false);
            }
        };
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const toggleDropdown = (value: boolean) => {
        if (value) {
            setRenderDropdown(value);
            setTimeout(() => {
                setShowDropdown(value);
            })
        } else {
            setShowDropdown(value);
            setTimeout(() => {
                setRenderDropdown(value);
            }, 300)
        }
    }

    return (
        <DropdownWrapper ref={dropdownRef}>
            <DropdownButton onClick={() => toggleDropdown(!showDropdown)}>
                {icon}
            </DropdownButton>
            <DropdownContent show={showDropdown || undefined} isRender={renderDropdown || undefined}>
                {items.map((item, index) => (
                    <DropdownItem className={item.className} key={index} onClick={() => {
                        toggleDropdown(false)
                        item.onClick();
                    }}>{item.title}</DropdownItem>
                ))}
            </DropdownContent>
        </DropdownWrapper>
    );
};

export default Dropdown;
