import {useMutation, useQuery, UseQueryResult} from "react-query";
import API from "../libs/api";
import {SeasonType} from "../types";

export const useTranslations = (word: string): UseQueryResult => {
    const fetchData = async () => {
        const {data, success} = await API.get(`/translate?word=${word}`);
        if (!success) {
            return [];
        }
        return data;
    }

    return useQuery(['getTranslations', word], fetchData, {
        enabled: !!word.length,
        staleTime: 0
    });
}

export const useSaveTranslations = () => {
    const sendData = async (payload: any) => {
        const resp = await API.post(`/translate`, payload);

        if (resp.success) {
            return {
                success: true
            }
        } else {
            return {
                success: false,
            }
        }

    }
    return useMutation(sendData);
}