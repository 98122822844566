import {createBrowserRouter} from "react-router-dom";
import React from "react";
import {Home} from "../../pages/home";
import {Seasons} from "../../pages/seasons";
import {Season} from "../../pages/season";
import {Video} from "../../pages/video";
import {Login} from "../../pages/auth/login";
import {Register} from "../../pages/auth/register";
import {AdminRoute, PrivateRoute} from "./PrivateRoute";
import {HomeDesigned} from "../../pages/home/homeDesigned";
import {VideoList} from "../../pages/videoList";
import {Dictionary} from "../../pages/dictionary";
import {Articles} from "../../pages/articles/list";
import {Article} from "../../pages/articles/single";
import {AdminArticles} from "../../pages/admin/articles/list";
import {Admin} from "../../pages/admin";
import {AdminArticle} from "../../pages/admin/articles/single";
import {AdminMenu} from "../../pages/admin/menu";
import {QuizPage} from "../../pages/QuizPage";
import {List} from "../../pages/dictionary/list";
import {NotFound} from "../../pages/notFound";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <HomeDesigned />,
    },
    {
        path: "/videos/:videoListId/seasons",
        element: <Seasons />,
    },
    {
        path: "/videos/:videoListId/seasons/:seasonId",
        element: <Season />,
    },
    {
        path: "/videos/:videoListId/seasons/:seasonId/:videoId",
        element: <Video />,
    },
    {
        path: "/videos/:videoId",
        element: <Video />,
    },
    {
        path: "/videos",
        element: <VideoList />,
    },
    {
        path: "/articles",
        element: <Articles />,
    },
    {
        path: "/articles/:articleId",
        element: <Article />,
    },
    {
        path: "/my-dictionaries/:dictionaryId",
        element: <PrivateRoute>
            <Dictionary />
        </PrivateRoute>,
    },
    {
        path: "/my-dictionaries",
        element: <List />,
    },
    {
        path: "/quiz/:quizName",
        element: <QuizPage />,
    },
    //Private admin routes
    {
        path: "/admin",
        element: <AdminRoute>
            <Admin />
        </AdminRoute>,
    },
    {
        path: "/admin/articles",
        element: <AdminRoute>
            <AdminArticles />
        </AdminRoute>,
    },
    {
        path: "/admin/articles/:articleId",
        element: <AdminRoute>
            <AdminArticle />
        </AdminRoute>,
    },
    {
        path: "/admin/menu",
        element: <AdminRoute>
            <AdminMenu />
        </AdminRoute>,
    },
    //Login
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/register",
        element: <Register />,
    },
    {
        path: "*",
        element: <NotFound />,
    },
]);