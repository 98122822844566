import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    RouterProvider,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {router} from "./libs/router";
import {ThemeProvider} from "styled-components";
import theme from "./theme";
import {ModalProvider} from "./context/ModalProvider";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,  // Data will never go stale once fetched
            refetchOnWindowFocus: false,  // Prevents refetching when window is refocused
            refetchOnReconnect: false  // Prevents refetching when the browser regains its internet connection
        },
    },
});

root.render(
    <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
            <ModalProvider>
                <RouterProvider router={router} />
            </ModalProvider>
        </ThemeProvider>
    </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
