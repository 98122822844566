import styled from "styled-components";
import theme from "../../theme";

export const StyledModal = styled.div<{visible?: boolean}>`
 
  .content {
    max-width: 700px;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 1px 1px 4px -2px #000;
    z-index: 21;
    overflow: hidden;
    opacity: ${({visible}) => visible ? 1 : 0 };
    transition: all .3s;
  }
  .overlap {
    z-index: 20;
    position: fixed;
    background: rgba(0, 0, 0, .5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${({visible}) => visible ? 1 : 0 };
    transition: all .3s;
  }
`;

export const ModalHeader = styled.div`
  padding: 20px;
  box-shadow: 0 1px 3px -1px #ccc;
  position: relative;

  h3 {
    display: block;
    padding-right: 30px
  }

  .modal-close {
    position: absolute;
    right: 20px;
    top: 20px;
    height: 20px;
    width: 20px;
    cursor: pointer;

    &:before, &:after {
      content: "";
      width: 2px;
      height: 100%;
      position: absolute;
      background: ${theme.colors.mainGray};
      top: 0;
      left: 50%;
    }

    &:before {
      transform: translateX(-50%) rotate(45deg);
    }

    &:after {
      transform: translateX(-50%) rotate(-45deg);
    }
  }
`;
export const ModalContent = styled.div`
  padding: 20px;
`;
export const ModalFooter = styled.div`
  padding: 20px;
  box-shadow: 0 -1px 3px -1px #ccc;
  display: flex;
  justify-content: end;
`;

export const DictionaryDropDown = styled.div`
  max-width: 50%;
  margin-top: 10px;
  @media (max-width: 580px) {
    max-width: 100%;
  }
`;