import React, {useMemo} from 'react';
import {useParams} from "react-router-dom";
import {useGetSeason} from "./api";
import {StyledVideosList} from "./styles";
import {Video} from "./components/video";
import {get} from 'lodash';
import {PageLayout} from "../../components/layout/PageLayout";
import {Breadcrumbs, BreadcrumbType} from "../../components/Breadcrumbs";
import {StyledTitle} from "../../styles";
import {PageWrapper} from "../home/styles";
import {Layout} from "../../components/layout";
import {Columns} from "../../components/columns";
import {useSplitArray} from "../../hooks/useSplitArray";

export const Season = () => {
    const {seasonId, videoListId} = useParams();
    const {data, isLoading} = useGetSeason(seasonId as string);
    const videos = get(data, 'videos', []);
    const season = get(data, 'season', {title: ''});

    const test = useSplitArray(videos, 4);

    const breadcrumbs:BreadcrumbType[] = useMemo(() => {

        const list: BreadcrumbType[] = [ {
            title: season ? season.title : ''
        }];

        if (videoListId) {
            list.push({
                title: 'Сезони',
                action: `/videos/${videoListId}/seasons/`
            });
        }

        list.push({
            title: 'Список відео',
            action: `/videos/`,
        });

        list.push({
            title: 'Домашня сторінка',
            action: '/'
        });

        return list.reverse();

    }, [season]);

    return  <Layout loading={isLoading}>
        <PageWrapper>
        <Breadcrumbs items={breadcrumbs} />

        <StyledTitle>{season.title}</StyledTitle>
            <Columns columns={4} gaps={20}>
                {videos.map((video) => {
                    return <Video
                        video={video}
                        seasonId={seasonId as string}
                        videoListId={videoListId as string}
                        key={video.id}
                    />
                })}
            </Columns>

        </PageWrapper>
    </Layout>
}