import React, {ReactNode} from 'react';
import {StyledButton, StyledIconButton} from "./styles";
import classNames from "classnames";

type Props = {
    text: string;
    onClick: () => void;
    disabled?: boolean;
    type?: 'delete' | 'edit' | 'create',
    icon?: ReactNode
}

export const Button = ({
    text,
    onClick,
    disabled,
    type,
    icon
}: Props) => {

    return (
        <>
            {icon ?
                <StyledIconButton onClick={!disabled ? onClick : () => {} }>
                    {icon}
                </StyledIconButton> :
                <StyledButton
                    onClick={!disabled ? onClick : () => {} }
                    background={"#28b9c7"}
                    hoverBackground={"#509da4"}
                    disabled={disabled}
                    className={classNames({delete: type === 'delete'})}
                >
                    {text}
                </StyledButton>
            }
        </>

    )
}