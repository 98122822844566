import {useMutation, useQuery, UseQueryResult} from "react-query";
import API from "../../../libs/api";
import {TMenuItem} from "./index";
import {get} from "lodash";

export const useGetMenu = (menuId: string, staleTime = Infinity): UseQueryResult<TMenuItem[]> => {
    const fetchData = async () => {
        const {data, success} = await API.get(`/menu/${menuId}`);
        if (!success) {
            return [];
        }
        return get(data, 'data', []);
    }

    return useQuery(['useGetMenu', menuId], fetchData, {
        staleTime
    });
}

export const useEdit = () => {
    const sendData = async (payload: any) => {
        const resp = await API.post(`/menu/${payload.menuId}`, payload);

        if (resp.success) {
            return {
                success: true
            }
        } else {
            return {
                success: false,
                error: 'Щось пішло не так'
            }
        }

    }
    return useMutation(sendData);
}

export const useDelete = () => {
    const sendData = async (payload: any) => {
        const resp = await API.delete(`/menu/${payload.menuId}/${payload.id}`);
        if (resp.success) {
            return {
                success: true
            }
        } else {
            return {
                success: false,
                error: 'Щось пішло не так'
            }
        }

    }
    return useMutation(sendData);
}