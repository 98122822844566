import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {isEqual} from 'lodash';
import {Button} from "../../../components/form/button";

const StyledItem = styled.div`
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  .textField {
    padding: 5px 10px;
    font-size: 18px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    width: 100%;
  }
`;

export type TFormItem = {
    url: string;
    title: string;
    order: string,
};

type TProps = {
    onAdd: (item: TFormItem) => void;
};

const defaultValues = {
    url: "",
    title: "",
    order: "",
}

export const AddForm = ({onAdd}: TProps) => {

    const [state, setState] = useState(defaultValues);

    const onChangeInput = (e: any) => {
        const {name, value} = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const onAddHandler = () => {
        onAdd(state);
        setState(defaultValues);
    }

    // console.log(state.isChecked);
    return <StyledItem>
        <input
            className="textField"
            type="text"
            name="title"
            placeholder="Заголовок"
            value={state.title}
            onChange={onChangeInput}
        />
        <input
            className="textField"
            type="text"
            name={"url"}
            placeholder={"Url"}
            value={state.url}
            onChange={onChangeInput}
        />
        <input
            className="textField"
            type="text"
            name={"order"}
            placeholder={"Order"}
            value={state.order}
            onChange={onChangeInput}
        />
        <div className="action">
            <Button
                onClick={onAddHandler}
                text="Зберегти"
            />
        </div>
    </StyledItem>
}