import React from 'react';
import {StyledVideos} from "./styles";
import {MediaLink} from "../../../../components/MediaLink";
import {Columns} from "../../../../components/columns";
import {Title} from "../../../../components/title";
import {ArrowButton} from "../../../../components/Button/arrowButton";
import {useNavigate} from "react-router-dom";
import {useGetRandom} from "./api";
import {get} from 'lodash';
import {VideoType} from "../../../../types";

const example: any = {
    cover: "/posters/seasons/11/2.jpeg",
    subtitles:"/subtitles/11/2.srt",
    title:"The Retraction Reaction"
}

export const RandomVideos = () => {
    const navigate = useNavigate();
    const {data} = useGetRandom();
    const videos: VideoType[] = get(data, 'data', []);

    return <StyledVideos>
        <Title>
            Відеоматеріали
        </Title>
        <div className="videos-list">
            <div className="videos-list__left">
                <Columns columns={4} gaps={15} style={{justifyContent: 'space-between'}}>
                    {videos.map((video, key) => {
                        return <MediaLink
                            title={video.title}
                            cover={video.cover}
                            url={`/videos/${video.id}`}
                            key={key}
                        />
                    })}
                </Columns>
            </div>
        </div>

    </StyledVideos>
}