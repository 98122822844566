import React, {useMemo} from 'react';
import {Quiz} from "../../components/Quiz";
import {get} from "lodash";
import {Breadcrumbs, BreadcrumbType} from "../../components/Breadcrumbs";
import {Layout} from "../../components/layout";
import {PageWrapper} from "../home/styles";
import {PageTitle} from "../../components/title/PageTitle";
import {useGetDictionary, Words} from "../dictionary/api";

export const QuizPage = () => {
    const title = 'Quiz - Мій Словник';
    const {data, isFetching} = useGetDictionary(0);
    const words = (get(data, 'data', []) as Words[]).map(({word, translation}) => ({word, translation}));

    const breadcrumbs:BreadcrumbType[] = useMemo(() => {

        const list = [];
        list.push({
            title,
        });

        list.push({
            title: 'Домашня сторінка',
            action: '/'
        });

        return list.reverse();

    }, []);

    return <Layout loading={isFetching}>
        <PageWrapper>
            <Breadcrumbs items={breadcrumbs} />
            <PageTitle style={{marginBottom: '30px'}} color='#f45542'>
                {title}
            </PageTitle>
            <Quiz
                words={words}
            />
        </PageWrapper>
    </Layout>
}