import styled from "styled-components";

export const StyledWrapper = styled.div`
  padding: 0;
  padding-top: 40px;
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
  .content {
    width: 70%;
  }
  .fullWidth {
    width: 100%;
  }
  .sidebar {
    width: 30%;
  }
`;

export const StyledPageLayout = styled.div`
  padding-top: 40px;
  .page-content {
    display: flex;
    gap: 40px;

    > div:first-child {
      flex: none;
      width: 250px;
      padding: 0;
    }
    > div:last-child {
      flex-grow: 1;
      padding: 20px 0;
      min-height: calc(100vh - 40px);
    }
  }
  
  .layout-content {
    max-width: 1200px;
  }
  
`;