import React, {createContext} from "react";
import {ContextType} from "./types";

export const defaultModalValues: ContextType = {
    state: {
        open: false,
        onSave: () => {},
        onClose: () => {},
        title: '',
        children: <></>,
    },
    setModal: () => {}
}

export const ModalContext = createContext<ContextType>(defaultModalValues);