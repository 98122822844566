import styled from "styled-components";

export const StyledVideo = styled.div`
  width: 300px;
  cursor: pointer;
  .img-container {
    width: 100%;
    img {
      width: 100%;
    }
  }
`;