import React from 'react';
import {Title} from "../../../../components/title";
import {StyledLink, StyledLinks} from "./styles";
import {Columns} from "../../../../components/columns";
import {useGetMenuOrdered} from "../../../../hooks/useGetMenuOrdered";
import {useSplitArray} from "../../../../hooks/useSplitArray";
import {TMenuItem} from "../../../admin/menu/MenuItem";

export const Links = () => {
    const menu = useGetMenuOrdered('middle-menu');
    const menus:TMenuItem[][] = useSplitArray(menu, 4, true);
    return <StyledLinks>
        <Title>Навчальні матеріали</Title>
        <div className="content">
            <Columns columns={4} gaps={15}>
                <div>
                    {(menus[0] || []).map((item) => {
                        return <StyledLink
                            key={item.id}
                            to={item.url}
                        >
                            {item.title}
                        </StyledLink>
                    })}
                </div>
                <div>
                    {(menus[1] || []).map((item) => {
                        return  <StyledLink
                            key={item.id}
                            to={item.url}
                        >
                            {item.title}
                        </StyledLink>
                    })}
                </div>
                <div>
                    {(menus[2] || []).map((item) => {
                        return  <StyledLink
                            key={item.id}
                            to={item.url}
                        >
                            {item.title}
                        </StyledLink>
                    })}
                </div>
                <div>
                    {(menus[3] || []).map((item) => {
                        return  <StyledLink
                            key={item.id}
                            to={item.url}
                        >
                            {item.title}
                        </StyledLink>
                    })}
                </div>

            </Columns>
        </div>
    </StyledLinks>
}