import styled from "styled-components";

export const StyledVideoWrapper = styled.div`
  display: flex;
  gap: 50px;
  justify-content: space-between;
  .video {
    width: 50%;
  }
  .subtitles {
    width: 50%;
    padding-right: 30px;
    overflow-y: auto;
    position: relative;
  }
  @media (max-width: 900px) {
    display: block;
    .video {
      width: 100%;
      margin-bottom: 30px;
    }
    .subtitles {
      width: 100%;
      padding-right: 0;
    }
  }
  
  .ReactVirtualized__Grid, .ReactVirtualized__Grid__innerScrollContainer {
    width: 100%!important;
    max-width: 100%!important;
  }
`;