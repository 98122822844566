import React, {useMemo} from 'react';
import {useParams} from "react-router-dom";
import {useGetVideo} from "./api";
import {Header} from "./components/Header";
import {Breadcrumbs, BreadcrumbType} from "../../components/Breadcrumbs";
import {useGetSeason} from "../season/api";
import {get} from "lodash";
import {PageWrapper} from "../home/styles";
import {Layout} from "../../components/layout";
import {VideoWithSubtitles} from "../../components/VideoWithSubtitles";

export const Video = () => {
    const {videoListId, seasonId, videoId} = useParams();

    const {data: seasonData} = useGetSeason(seasonId as string);
    const season = get(seasonData, 'season', {title: ''});

    const {data: vdata, isLoading} = useGetVideo(videoId as string);
    const video = vdata || null;

    const breadcrumbs:BreadcrumbType[] = useMemo(() => {

        const list: BreadcrumbType[] = [ {
            title: video ? video.title : ''
        }];

        if (seasonId && videoListId) {
            list.push({
                title: season? season.title : 'Сезон',
                action: `/videos/${videoListId}/seasons/${seasonId}`
            });
        }

        if (videoListId) {
            list.push({
                title: 'Сезони',
                action: `/videos/${videoListId}/seasons/`
            });
        }

        list.push({
            title: 'Список відео',
            action: `/videos/`
        });

        list.push({
            title: 'Домашня сторінка',
            action: '/'
        });

        return list.reverse();

    }, [video, season]);

    return <Layout loading={isLoading}>
        <PageWrapper>
            <Breadcrumbs items={breadcrumbs} />
            {video && <Header cover={video.cover} title={video.title} />}
            <VideoWithSubtitles videoId={videoId as string} listHeight={600} rowHeight={50} />
        </PageWrapper>
    </Layout>
}