import React from 'react';
import {Title} from "../../../../components/title";
import {useGetRandom} from "../RandomVideos/api";
import {get} from "lodash";
import {VideoWithSubtitles} from "../../../../components/VideoWithSubtitles";
import {Button} from "../../../../components/Button";
import {useNavigate} from "react-router-dom";
import {SideControls} from "./styles";

export const VideoWithSubtitle = () => {
    const navigate = useNavigate();
    const {data: randomVideos} = useGetRandom();
    const videoRandom: any = get(randomVideos, 'data[0]', null);
    const videoId = videoRandom ? videoRandom.id : '1';

    return <>
        <Title style={{marginBottom: '30px'}}>
            Дивись та вчись
        </Title>
        <VideoWithSubtitles videoId={videoId as string} listHeight={400} rowHeight={50} />
        <SideControls>
            <Button
                content='Відкрити деталі'
                onClick={() => {
                    navigate(`/videos/${videoId}`)
                }}
                size="medium"
            />
        </SideControls>
    </>
}