import React from 'react';
import {StyledSection} from "./styles";
import classNames from "classnames";

export type ButtonSize = 'small' | 'medium' | 'large';

type Props = {
    onClick: () => void;
    content: string;
    background?: string;
    hoverBackground?: string;
    size?: ButtonSize
}

export const Button = ({
    onClick,
    content,
    background,
    hoverBackground,
    size
}: Props) => {
    return <StyledSection
        background={background}
        hoverBackground={hoverBackground}
        onClick={onClick}
        size={size}
        className={classNames(`button-${size}`)}
    >{content}</StyledSection>
}