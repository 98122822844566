import React, {useMemo} from 'react';
import {Breadcrumbs, BreadcrumbType} from "../../components/Breadcrumbs";
import {PageTitle} from "../../components/title/PageTitle";
import {useGetArticle} from "./api";
import {useParams} from "react-router-dom";
import {get} from 'lodash';
import {ArticleType} from "../../types";
import styled from "styled-components";
import {StyledTable} from "../../components/Table/styles";
import {Layout} from "../../components/layout";
import {PageWrapper} from "../home/styles";
import theme from "../../theme";

const StyledContent = styled.div`
  padding-bottom: 40px;
  p {
    line-height: 23px;
    margin-bottom: 20px;
  }
  ul, ol {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  li {
    margin-bottom: 5px;
  }
  
  h2 {
    margin-bottom: 10px;
    color: ${theme.colors.headerBackground}
  }
`;

export const Article = () => {
    const {articleId} = useParams();
    const {data, isFetching} = useGetArticle(articleId);
    const emptyArticle: ArticleType = {
        title: '',
        content: ''
    };
    const article: ArticleType = get(data, 'data', emptyArticle);

    const breadcrumbs:BreadcrumbType[] = useMemo(() => {

        const list = [];
        list.push({
            title: article.title,
        });

        list.push({
            title: 'Навчальні Матеріали',
            action: '/articles'
        });

        list.push({
            title: 'Домашня сторінка',
            action: '/'
        });

        return list.reverse();

    }, [article]);

    return <Layout loading={isFetching}>
        <PageWrapper>
            <Breadcrumbs items={breadcrumbs} />
            <PageTitle style={{marginBottom: '30px'}} color='#f45542'>
                {article.title}
            </PageTitle>
            <StyledContent>
                <StyledTable>
                    <div dangerouslySetInnerHTML={{ __html: article.content }} />
                </StyledTable>
            </StyledContent>
        </PageWrapper>
    </Layout>
}