import {BreadcrumbType} from "../../../components/Breadcrumbs";
import {useMemo} from "react";

export const useArticlesState = () => {

    const breadcrumbs:BreadcrumbType[] = useMemo(() => {

        const list = [];
        list.push({
            title: 'Навчальні матеріали',
        });

        list.push({
            title: 'Адміністрування',
            action: '/admin'
        });

        list.push({
            title: 'Домашня сторінка',
            action: '/'
        });

        return list.reverse();

    }, []);

    const breadcrumbsSingle:BreadcrumbType[] = useMemo(() => {

        const list = [];
        list.push({
            title: 'Стаття',
        });

        list.push({
            title: 'Навчальні матеріали',
            action: '/admin/articles'
        });

        list.push({
            title: 'Адміністрування',
            action: '/admin'
        });

        list.push({
            title: 'Домашня сторінка',
            action: '/'
        });

        return list.reverse();

    }, []);


    return {
        breadcrumbs,
        breadcrumbsSingle
    }
}