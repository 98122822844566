import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import React, {useEffect, useState} from 'react';
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import {PageLayout} from "../../../components/layout/PageLayout";
import {useArticlesState} from "./useArticlesState";
import styled from "styled-components";
import theme from "../../../theme";
import {TextField} from "../../../components/form/textField";
import {Formik} from "formik";
import * as Yup from "yup";
import {Button} from "../../../components/form/button";
import {useEdit, useGetArticle} from "./api";
import {useParams} from "react-router-dom";
import {get} from 'lodash';

const StyledEditing  = styled.div`
  padding-top: 30px;
  textarea {
    min-height: 200px;
    width: 100%;
    padding: 10px;
    outline: none;
    border: 1px solid ${theme.colors.borderInputs};
    border-radius: 4px;
  }
  
  .toggleButton {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    cursor: pointer;
    color: ${theme.colors.headerBackground};
    user-select: none;
  }
`;

const StyledAction = styled.div`
  margin-top: 20px;
`;

export const AdminArticle = () => {

    const emptyArticle = {
        title: 'Новий',
        content: '',
        id: undefined
    }

    const {breadcrumbsSingle} = useArticlesState();
    const {articleId} = useParams();
    const [isHtml, setIsHtml] = useState(false);
    const [state, setState] = useState('');
    const {mutateAsync, isLoading} = useEdit();
    const {data, isFetching} = useGetArticle(articleId);
    const article = get(data, 'data', emptyArticle);

    useEffect(() => {
        if (articleId && articleId !== 'new') {
            setState(article.content);
        }
    }, [article]);

    const onChange = (text: string) => {
        setState(text);
    }

    const initialValues = article;

    const validationSchema = Yup.object({
        title: Yup.string()
            .required("Обов'язкове поле"),
    });

    const onSubmit = async (values: any) => {
        const payload = {
            title: values.title,
            content: state,
            id: article.id || ''
        }
        await mutateAsync(payload)
    }

    breadcrumbsSingle[breadcrumbsSingle.length - 1] = {
       title:  article.title
    }

    return <PageLayout loading={isFetching}>
        <Breadcrumbs items={breadcrumbsSingle} />

        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ isSubmitting, submitForm, isValid }) => (
                <form>

                    <TextField
                        name="title"
                        label="Заголовок"
                        placeholder="Заголовок"
                    />

                    <StyledEditing>

                        <div
                            onClick={() => setIsHtml(!isHtml)}
                            className="toggleButton"
                        >{isHtml ? 'Редагування тексту' : 'Редагування HTML'}</div>

                        {isHtml ?
                            <textarea name="" id="" value={state} onChange={(e) => {
                                onChange(e.target.value);
                            }} />:
                            <CKEditor
                                editor={ ClassicEditor }
                                data={state}
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    onChange(data);
                                } }
                            />
                        }

                    </StyledEditing>
                    <StyledAction>
                        <Button
                            text="Зберегти"
                            onClick={submitForm}
                            disabled={isLoading}
                        />
                    </StyledAction>

                </form>
                )}
        </Formik>
    </PageLayout>
}